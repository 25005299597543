import { FacebookPixel, GoogleAnalytics } from '../../../services'
import { getFacebookPixelContentCategory } from '../utils'

import { ORDER_COMPLETED_ORDER } from './queries'

import initApollo from 'src/api'
import { API } from 'src/api'

export const orderCompleted = async (config: { basketId: string }) => {
  const client = initApollo({})

  try {
    const response = await client.query<API.UseEcomOrderCompletedOrder>({
      query: ORDER_COMPLETED_ORDER,
      variables: {
        basketId: config.basketId,
      },
    })

    const order = response.data.order

    if (order) {
      // Facebook Pixel
      FacebookPixel.track('Purchase', {
        content_type: 'product',
        content_ids: order.lineItems?.nodes.map((lineItem) => lineItem.product.id) || [],
        value: order.total.value,
        contents: order.lineItems?.nodes.map((lineItem) => ({
          id: lineItem.product.id,
          quantity: lineItem.quantity,
          item_price: lineItem.price.value,
        })),
        num_items: order.lineItems?.nodes.length,
        currency: order.total.currencyCode,
        content_category: getFacebookPixelContentCategory(
          order.lineItems?.nodes.map((lineItem) => lineItem.product.__typename),
        ),
      })

      // Google Analytics
      GoogleAnalytics.track('purchase', {
        transaction_id: order.id,
        value: order.total.value,
        currency: order.total.currencyCode,
        tax: 0,
        shipping: 0,
        items: order.lineItems?.nodes.map((lineItem) => ({
          id: lineItem.product.id,
          name: lineItem.product.title,
          quantity: lineItem.quantity,
          price: lineItem.price.value,
          variant: lineItem.variant.id,
          category:
            lineItem.product.__typename === 'ProductCourse'
              ? lineItem.product.difficultyLevel
              : undefined,
        })),
      })
    }
  } catch (e) {
    console.log(e)
  }
}
