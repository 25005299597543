import React, { useEffect } from 'react'
import NextScript from 'next/script'

import { HELPSCOUT_BEACON_ID } from 'src/config'

const init = () => {
  window.Beacon?.call(undefined, 'init', HELPSCOUT_BEACON_ID)
}

const logout = () => {
  window.Beacon?.call(undefined, 'logout')
}

const identify = (config: { name: string; email: string }) => {
  window.Beacon?.call(undefined, 'identify', {
    name: config.name,
    email: config.email,
  })
}

const Script = () => {
  useEffect(() => {
    init()
  }, [])

  return (
    <NextScript
      id="helpscout-1"
      // NOTE: can't use lazyOnload here because we need to call the init function
      // which doesn't work with the lazyOnload strategy
      // onLoad also doesn't work with this script as it is inline
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`,
      }}
    />
  )
}

const HelpScout = {
  Script,
  identify,
  logout,
}

export default HelpScout
