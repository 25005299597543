import { anonymous } from './anonymous'
import { checkoutStarted } from './checkoutStarted'
import { loggedIn } from './loggedIn'
import { loggedOut } from './loggedOut'
import { orderCompleted } from './orderCompleted'
import { pageViewed } from './pageViewed'
import { productAdded } from './productAdded'
import { emailSubmitted } from './emailSubmitted'
import { subscriptionOrderCompleted } from './subscriptionOrderCompleted'

export default {
  subscriptionOrderCompleted,
  anonymous,
  emailSubmitted,
  checkoutStarted,
  loggedIn,
  loggedOut,
  orderCompleted,
  pageViewed,
  productAdded,
}
