import { gql } from '@apollo/client'

import { PRODUCT_BOOK_FRAGMENT, PRODUCT_COURSE_FRAGMENT } from '../fragments'

export const CHECKOUT_STARTED_BASKET = gql`
  query UseEcomCheckoutStartedBasket {
    basket {
      id
      value {
        value
      }

      discounts {
        code
      }
      totalDiscount {
        value
        currencyCode
      }
      total {
        value
        currencyCode
      }

      lineItems {
        quantity
        price {
          value
          currencyCode
        }
        variant {
          id
        }
        product {
          ... on ProductCourse {
            ...EventsProductCourse
          }

          ... on ProductBook {
            ...EventsProductBook
          }
        }
      }
    }
  }

  ${PRODUCT_COURSE_FRAGMENT}
  ${PRODUCT_BOOK_FRAGMENT}
`
