import { FacebookPixel, GoogleAnalytics } from '../../../services'

import { INVOICE } from './queries'

import initApollo, { API } from 'src/api'

export const subscriptionOrderCompleted = async (config: { invoiceId: string }) => {
  const client = initApollo({})

  try {
    const response = await client.query<
      API.UseEcom_SubscriptionOrderCompleted_Invoice,
      API.UseEcom_SubscriptionOrderCompleted_InvoiceVariables
    >({
      query: INVOICE,
      variables: {
        id: config.invoiceId,
      },
    })

    const invoice = response.data.membershipInvoice
    const session = response.data.session

    if (invoice) {
      // Facebook Pixel
      FacebookPixel.track('Purchase', {
        content_type: 'product',
        content_ids: [invoice.product.id],
        value: invoice.total.value,
        currency: invoice.total.currencyCode,
      })

      // Google Analytics
      GoogleAnalytics.track('purchase', {
        transaction_id: `membership_${invoice.id}`,
        value: invoice.total.value,
        currency: invoice.total.currencyCode,
        tax: 0,
        shipping: 0,
        items: [
          {
            id: invoice.product.id,
            name: invoice.product.title,
            quantity: 1,
            price: invoice.total.value,
            category: 'MembershipProduct',
          },
        ],
      })

      if (session) {
        GoogleAnalytics.setUserProperties({
          userType: session.type,
          membershipStatus: session.membership?.status,
        })
      }
    }
  } catch (e) {
    console.log(e)
  }
}
