import { SiteFooter } from '@superhi/design'

import { buildPath } from 'src/routing'
import { API } from 'src/api'
import { SUPERLIBRARY_URL } from 'src/config'

export const ITEMS: React.ComponentProps<typeof SiteFooter>['items'] = [
  {
    display: 'Learn',
    items: [
      {
        display: 'Code',
        href: buildPath('courses-index', {
          query: {
            subject: API.ProductCourseSubject.CODE,
          },
        }),
      },
      {
        display: 'Design',
        href: buildPath('courses-index', {
          query: {
            subject: API.ProductCourseSubject.DESIGN,
          },
        }),
      },
      {
        display: 'Project Management',
        href: buildPath('courses-index', {
          query: {
            subject: API.ProductCourseSubject.PROJECT_MANAGEMENT,
          },
        }),
      },
      {
        display: 'Plan, Design + Code Your First Website',
        href: buildPath('courses-view', {
          params: {
            slug: 'plan-design-and-code-your-first-website',
          },
        }),
      },
      {
        display: 'SuperHi Unlimited',
        href: buildPath('membership-product'),
        // isNew: true,
      },
      {
        display: 'SuperHi For Teams',
        href: buildPath('teams'),
        // isNew: true,
      },
      {
        display: 'All Courses',
        href: buildPath('courses-index'),
      },
      {
        display: 'Learning paths',
        href: buildPath('collections-index'),
      },
    ],
  },
  {
    display: 'Resources',
    items: [
      {
        display: 'Library',
        href: `${SUPERLIBRARY_URL}`,
        isExternal: true,
      },
      {
        display: 'Guides',
        href: `${SUPERLIBRARY_URL}/guides`,
        isExternal: true,
      },
      {
        display: 'Tutorials',
        href: `${SUPERLIBRARY_URL}/tutorials`,
        isExternal: true,
      },
      { display: 'Learn To Code Book', href: buildPath('book') },
      {
        display: 'SuperHi Code Editor',
        href: buildPath('editor'),
      },
    ],
  },
  {
    display: 'Company',
    items: [
      { display: 'About SuperHi', href: buildPath('about') },
      { display: 'Hall of Fame', href: buildPath('hall-of-fame-index') },
      { display: 'Press', href: buildPath('press') },
      { display: 'Jobs', href: buildPath('jobs-index') },
    ],
  },
  {
    display: 'Support',
    items: [
      { display: 'Help Center', href: buildPath('help-center-index') },
      {
        display: 'Code of Conduct',
        href: buildPath('code-of-conduct'),
      },
      {
        display: 'Discrimination Policy',
        href: buildPath('discrimination-policy'),
      },
      {
        display: 'Return Policy',
        href: buildPath('return-policy'),
      },
      { display: 'Contact Us', href: buildPath('contact') },
    ],
  },
]

export const SOCIAL_LINKS: React.ComponentProps<typeof SiteFooter>['socialLinks'] = [
  {
    name: 'facebook',
    href: 'https://www.facebook.com/superhidotcom',
  },
  {
    name: 'instagram',
    href: 'https://www.instagram.com/hisuperhi/',
  },
  {
    name: 'twitter',
    href: 'https://twitter.com/superhi_',
  },
  {
    name: 'youtube',
    href: 'https://www.youtube.com/c/superhi',
  },
]

export const BOTTOM_LINKS: React.ComponentProps<typeof SiteFooter>['bottomLinks'] = [
  {
    display: 'Terms of Use',
    href: buildPath('terms-of-use'),
  },
  {
    display: 'Privacy Policy',
    href: buildPath('terms-of-use') + '#privacy-policy',
  },
]
