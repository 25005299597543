import { gql } from '@apollo/client'

export const FRAGMENT = gql`
  fragment AuthSession on Session {
    id
    type
    isAdmin
  }
`

export const IS_LOGGED_IN = gql`
  query IsLoggedIn {
    session {
      ...AuthSession
    }
  }

  ${FRAGMENT}
`
