import React from 'react'
import NextScript from 'next/script'

import { Track, Set } from './global'

import { GOOGLE_ANALYTICS_ID } from 'src/config'

const identify = (config?: { id: string }) => {
  ;(window as any).gtag?.call(undefined, 'config', GOOGLE_ANALYTICS_ID!, {
    user_id: config?.id,
  })
}

const setUserProperties = (data: Parameters<Set>[2]) => {
  window.gtag?.call(undefined, 'set' as any, 'user_properties' as any, data)
}

const track = (eventName: Parameters<Track>[1], data: Parameters<Track>[2]) => {
  window.gtag?.call(undefined, 'event', eventName, data)
}

const Script = () => (
  <>
    <NextScript
      id="google-analytics-1"
      async
      src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}
    />
    <NextScript
      id="google-analytics-2"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag("js", new Date());
    
      gtag("config", "${GOOGLE_ANALYTICS_ID}", { "send_page_view": false });  
      gtag("config", "AW-862581725");       
    `,
      }}
    />
  </>
)

const GoogleAnalytics = {
  Script,
  identify,
  track,
  setUserProperties,
}

export default GoogleAnalytics
