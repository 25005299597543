import { gql } from '@apollo/client'

import { PRODUCT_BOOK_FRAGMENT, PRODUCT_COURSE_FRAGMENT } from '../fragments'

export const PRODUCT_ADDED = gql`
  query UseEcomProductAdded($id: ID!) {
    productVariant(id: $id) {
      id
      priceConversion {
        value
        currencyCode
      }
      product {
        ... on ProductCourse {
          ...EventsProductCourse
        }
        ... on ProductBook {
          ...EventsProductBook
        }
      }
    }
  }

  ${PRODUCT_COURSE_FRAGMENT}
  ${PRODUCT_BOOK_FRAGMENT}
`
