import React from 'react'
import omitBy from 'lodash.omitby'
import { compile } from 'path-to-regexp'
import { Router, useRouter } from 'next/router'

import ROUTES from './routes'

import { useAppState } from 'src/components/App/components/StateProvider'
import querystring from 'querystring'

export const useGetCanonicalUrl = () => {
  const pathname = useRouter().asPath
  const appState = useAppState()

  return React.useMemo(() => {
    if (typeof window !== 'undefined') {
      return window.location.href.replace(window.location.search, '')
    }

    return appState.canonicalUrl!
    /* eslint-ignore-next-line react-hooks/exhaustive-deps */
  }, [appState.canonicalUrl, pathname])
}

type QueryParamValue = string | string[] | undefined

export const queryParamaterValueToString = (value: QueryParamValue) =>
  Array.isArray(value) ? value[0] : value

export const buildQuery = (query: object) =>
  querystring.stringify(omitBy({ ...query }, (v) => v === undefined))

type Parsed<T> = Record<keyof T, QueryParamValue>

export function parseQuery<T extends { [key: string]: string | number }>(query: string): Parsed<T> {
  return querystring.parse(query.substring(1)) as Parsed<T>
}

export type ParseAndFormatQueryConfig<Query> = {
  [K in keyof Query]: ParseQueryValueConfig
}

export function parseAndFormatQuery<Query extends { [key: string]: string | number }>(
  query: string,
  config: ParseAndFormatQueryConfig<Query>,
) {
  const parsedQuery = parseQuery<Query>(query)

  return Object.entries<QueryParamValue>(parsedQuery).reduce((acc, [k, v]) => {
    const key = k as keyof Parsed<Query>

    if (config[key]) {
      return {
        ...acc,
        [key]: parseQueryValue(config[key], v),
      }
    }

    return acc
  }, {} as Query)
}

type ParseQueryValueConfig = {
  type: 'string' | 'number' | 'boolean'
  isArray?: boolean
}

export const parseQueryValue = (config: ParseQueryValueConfig, value?: string | string[]) => {
  switch (config.type) {
    case 'string': {
      if (config.isArray) {
        return Array.isArray(value) ? value : value ? [value] : undefined
      }

      return queryParamaterValueToString(value)
    }

    case 'boolean': {
      if (config.isArray) {
        return Array.isArray(value) ? value.map(Boolean) : value ? [Boolean(value)] : undefined
      }

      return Boolean(queryParamaterValueToString(value))
    }

    case 'number': {
      if (config.isArray) {
        return Array.isArray(value) ? value.map(Number) : value ? [Number(value)] : undefined
      }

      return Number(queryParamaterValueToString(value))
    }
  }
}

export const buildPath = (
  routeName: keyof typeof ROUTES,
  options?: {
    params?: object
    query?: object
  },
) => {
  const path = ROUTES[routeName]

  if (path) {
    return `${compile(path)(options?.params)}${
      options?.query ? `?${buildQuery(options.query)}` : ''
    }`
  }

  return '/'
}

export const buildUrl = (
  baseUrl: string,
  routeName: Parameters<typeof buildPath>[0],
  options?: Parameters<typeof buildPath>[1],
) => `${baseUrl}${buildPath(routeName, options)}`

export const buildUrlClient = (path: string) => {
  try {
    return `${window.location.href.replace(window.location.pathname, '')}${path}`
  } catch (e) {
    return '/'
  }
}

const ACCOUNT_ROUTES = {
  home: '/',
  login: '/login',
  logout: '/logout',
  membership: '/membership',
  'membership-cancel': '/membership/cancel',
  'membership-billing': '/membership/billing',
  'membership-payment': '/membership/payment',
  'reset-password-request': '/reset-password',
  'reset-password-reset': '/reset-password/:passwordToken',
  'order-history': '/order-history',
  settings: '/settings',
  'settings-details': '/settings/details',
  'settings-password': '/settings/password',
  signup: '/signup',
}

export const buildAccountPath = (
  routeName: keyof typeof ACCOUNT_ROUTES,
  options?: {
    params?: object
    query?: object
  },
) => {
  const path = ACCOUNT_ROUTES[routeName]

  if (path) {
    return `${compile(path)(options?.params)}${
      options?.query ? `?${buildQuery(options.query)}` : ''
    }`
  }

  return '/'
}

export const buildAccountUrl = (
  baseUrl: string,
  routeName: Parameters<typeof buildAccountPath>[0],
  options?: {
    params?: Parameters<typeof buildAccountPath>[1]
    redirectUrl?: string
    includeAuthToken?: boolean
  },
) => {
  const url = new URL(`${baseUrl}${buildAccountPath(routeName, options?.params)}`)

  if (options?.redirectUrl) {
    url.searchParams.set('redirectExternal', options.redirectUrl)
  }

  if (options?.includeAuthToken) {
    url.searchParams.set('redirectExternal', String(options.includeAuthToken))
  }

  return url.toString()
}
