import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import omitBy from 'lodash.omitby'

import { removeUndefined } from '../utils'

import { parseQuery } from 'src/routing'
import { ParsedUrlQuery } from 'querystring'
import COOKIE_MANAGER from './'

export const setClientCookiesFromQueryParams = () => {
  if (process.browser) {
    const query = parseQuery(window.location.search)

    Object.values(COOKIE_MANAGER).forEach((cookie) => {
      if (cookie.queryParamName) {
        const value = query[cookie.queryParamName]

        if (value) {
          cookie.set(value)
        }
      }
    })
  }
}

export const getAllClientCookies = (): object =>
  Object.values(COOKIE_MANAGER).reduce(
    (acc, cookie) => ({
      ...acc,
      [cookie.key]: cookie.get(),
    }),
    {},
  )

export const transformCookiesToHeaders = (cookies: { [key: string]: string | undefined }) =>
  Object.entries(cookies).reduce((acc, [key, value]) => {
    const config = COOKIE_MANAGER[key as keyof typeof COOKIE_MANAGER]

    if (config && value) {
      const transform = config.headerTransform

      if (transform) {
        return {
          ...omitBy(acc, (_, key) => key === config.key),
          [transform.key]: transform.transformer(value),
        }
      }
    }

    return acc
  }, cookies)

export const mergeServerQueryParamsWithCookies = (
  requestCookies: NextApiRequestCookies,
  requestQuery: ParsedUrlQuery,
) => {
  const newCookies = Object.entries(COOKIE_MANAGER).reduce((acc, [cookieName, cookie]) => {
    if (cookie.queryParamName) {
      const queryValue = requestQuery[cookie.queryParamName]

      if (queryValue) {
        return {
          ...acc,
          [cookieName]: queryValue,
        }
      }
    }

    return acc
  }, {})

  return {
    ...removeUndefined(requestCookies),
    ...removeUndefined(newCookies),
  }
}
