import { ThemeProvider } from '@superhi/design'
import { FontHeadComponent } from '@superhi/design'
import React from 'react'
import Head from 'next/head'
import { ApolloProvider } from '@apollo/client'
import { AppProps } from 'next/app'
import { NextPage } from 'next'

import LinkComponent from '../LinkNext'
import SEO from '../SEO'

import Utils from './components/Utils'
import SiteHeader from './components/SiteHeader'
import SiteFooter from './components/SiteFooter'
import { StateProvider } from './components/StateProvider'

import { setClientCookiesFromQueryParams } from 'src/cookies/utils'
import { PageConfig } from 'src/routing/types'
import initApollo from 'src/api'

setClientCookiesFromQueryParams()
const client = initApollo({})

type Props = AppProps & {
  Component: NextPage & PageConfig<unknown>
}

const App: React.FC<React.PropsWithChildren<Props>> = ({ Component, pageProps }) => {
  const layout = Component?.route?.layout

  return (
    <>
      <Head>
        <FontHeadComponent />
      </Head>

      <ApolloProvider client={client}>
        <StateProvider>
          <Utils />

          <ThemeProvider LinkComponent={LinkComponent}>
            <SEO />
            {layout !== 'none' && <SiteHeader />}

            <Component {...pageProps} />

            {layout !== 'none' && <SiteFooter />}
          </ThemeProvider>
        </StateProvider>
      </ApolloProvider>
    </>
  )
}

export default App
