import { gql } from '@apollo/client'

import { PRODUCT_BOOK_FRAGMENT, PRODUCT_COURSE_FRAGMENT } from '../fragments'

export const ORDER_COMPLETED_ORDER = gql`
  query UseEcomOrderCompletedOrder($basketId: ID!) {
    order(basketId: $basketId) {
      id
      value {
        value
      }
      discounts {
        nodes {
          code
        }
      }

      customerDetails {
        email
      }

      value {
        value
      }
      totalDiscount {
        value
      }
      total {
        value
        currencyCode
      }

      lineItems {
        nodes {
          quantity
          price {
            value
            currencyCode
          }
          variant {
            id
            title
            priceConversion {
              value
            }
          }
          product {
            ... on ProductCourse {
              ...EventsProductCourse
            }

            ... on ProductBook {
              ...EventsProductBook
            }
          }
        }
      }
    }
  }

  ${PRODUCT_COURSE_FRAGMENT}
  ${PRODUCT_BOOK_FRAGMENT}
`
