import React from 'react'
import {
  getImgProps,
  SiteHeader as SiteHeaderComponent,
  SuperHiLogo,
  SkipToContentLink,
} from '@superhi/design'
import { useQuery } from '@apollo/client'
import { useRouter } from 'next/router'

import { buildAccountUrl } from '../../../../routing/utils'
import { useAppDispatch, useAppState } from '../StateProvider'

import { BASKET, SESSION, SETTINGS } from './queries'
import { NAV_ITEMS } from './config'

import { buildPath } from 'src/routing'
import COOKIE_MANAGER from 'src/cookies'
import { ACCOUNT_URL, DASHBOARD_URL, EDITOR_URL, SITE_URL, SUPERLIBRARY_URL } from 'src/config'
import { API } from 'src/api'

const HOME_URL = buildPath('home')
const CART_URL = buildPath('cart')

const SiteHeader = () => {
  const router = useRouter()
  const { data } = useQuery<API.SiteHeaderComponentBasket>(BASKET, {
    ssr: false,
  })
  const { data: settingsData } = useQuery<API.SiteHeaderComponentSettings>(SETTINGS, {
    ssr: false,
  })
  const { data: sessionData } = useQuery<API.SiteHeaderComponentSession>(SESSION, {
    fetchPolicy: 'cache-and-network',
    ssr: false,
  })
  const LOGIN_URL = React.useMemo(
    () =>
      buildAccountUrl(ACCOUNT_URL, 'login', {
        redirectUrl: `${SITE_URL}${router.asPath}`,
      }),
    [router.asPath],
  )

  const numLineItems = data?.basket?.lineItems?.reduce((acc, item) => acc + item.quantity, 0) || 0
  const session = sessionData?.session

  const userItems = React.useMemo<
    React.ComponentProps<typeof SiteHeaderComponent>['userItems']
  >(() => {
    const items = [
      {
        display: 'Profile',
        href: `${DASHBOARD_URL}/users/${session?.username}`,
        isExternal: true,
      },
      {
        display: 'Order history',
        href: buildAccountUrl(ACCOUNT_URL, 'order-history'),
        isExternal: true,
      },
      {
        display: 'Settings',
        href: buildAccountUrl(ACCOUNT_URL, 'settings'),
        isExternal: true,
      },
    ]

    if (session?.membership) {
      items.splice(1, 0, {
        display: 'Membership',
        href: buildAccountUrl(ACCOUNT_URL, 'membership'),
        isExternal: true,
      })
    }

    return [
      {
        items: [
          {
            display: 'Student Dashboard',
            href: DASHBOARD_URL,
            isExternal: true,
          },
          {
            display: 'SuperLibrary',
            href: SUPERLIBRARY_URL,
            isExternal: true,
          },
        ],
      },
      { items },
      {
        items: [
          {
            display: 'Log out',
            href: buildAccountUrl(ACCOUNT_URL, 'logout', {
              redirectUrl: `${SITE_URL}${router.asPath}`,
            }),
            isExternal: true,
            newTab: false,
          },
        ],
      },
    ]
  }, [session, router.asPath])

  const userImage = React.useMemo(
    () =>
      session?.avatar?.url
        ? getImgProps(session.avatar.url, {
            small: 5,
            medium: 5,
            large: 5,
            xlarge: 5,
          })
        : undefined,
    [session?.avatar?.url],
  )

  const announcement = settingsData?.settings.announcement

  const appState = useAppState()
  const appDispatch = useAppDispatch()

  const handleBannerOnClose = () => {
    if (announcement) {
      COOKIE_MANAGER['banner-dismissed-id'].set(announcement.id)
      appDispatch({ type: 'setBannerDismissedId', payload: announcement.id })
    }
  }

  const showBanner = !!announcement && appState['banner-dismissed-id'] !== announcement.id

  return (
    <>
      <SkipToContentLink skipTo="main" verticalOffset={showBanner ? '170px' : '110px'}>
        Skip to Content
      </SkipToContentLink>
      <SiteHeaderComponent
        iconButtons={[
          {
            ariaLabel: 'View your cart',
            iconName: 'cart',
            href: CART_URL,
            badge: numLineItems > 0 ? numLineItems : undefined,
          },
        ]}
        logInButton={{
          text: 'Log in',
          href: LOGIN_URL,
          version: 'filled',
          isExternal: true,
          newTab: false,
        }}
        logoHref={HOME_URL}
        logo={<SuperHiLogo height={{ small: 32, medium: 42, large: 42 }} />}
        items={NAV_ITEMS}
        closeOn={router.asPath}
        user={
          session
            ? {
                image: userImage,
                name: session.name || undefined,
                email: session.email,
              }
            : undefined
        }
        userItems={userItems}
        bannerText={showBanner ? announcement?.body : undefined}
        bannerOnClose={handleBannerOnClose}
      />
    </>
  )
}

export { USER_FRAGMENT } from './queries'
export default SiteHeader
