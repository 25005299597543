import { Item } from '@superhi/design/src/components/navigation/SiteHeader/types'
import { SiteHeader } from '@superhi/design'

import { buildPath } from 'src/routing'
import { SUPERLIBRARY_URL } from 'src/config'

const RESOURCES: Item = {
  display: 'Resources',
  items: [
    {
      display: 'Library',
      href: `${SUPERLIBRARY_URL}`,
      isExternal: true,
    },
    {
      display: 'Guides',
      href: `${SUPERLIBRARY_URL}/guides`,
      isExternal: true,
    },
    {
      display: 'Tutorials',
      href: `${SUPERLIBRARY_URL}/tutorials`,
      isExternal: true,
    },
    {
      display: 'Book',
      href: buildPath('book'),
    },
    {
      display: 'SuperHi Editor',
      href: buildPath('editor'),
    },
  ],
}

const COURSES: Item = {
  display: 'Courses',
  items: [
    { display: 'All Courses', href: buildPath('courses-index') },
    { display: 'Learning paths', href: buildPath('collections-index') },
  ],
}

const STUDENT_WORK: Item = {
  display: 'Hall of Fame',
  href: buildPath('hall-of-fame-index'),
}

const TEAMS: Item = {
  display: 'Teams',
  href: buildPath('teams'),
}

const MEMBERSHIP: Item = {
  display: 'Membership',
  href: buildPath('membership-product'),
}

export const NAV_ITEMS: React.ComponentProps<typeof SiteHeader>['items'] = [
  COURSES,
  RESOURCES,
  MEMBERSHIP,
  TEAMS,
  STUDENT_WORK,
]
