import { gql } from '@apollo/client'

import { BASKET_MANAGER_LINE_ITEM } from './fragments'

export const BASKET = gql`
  query BasketBasket {
    basket {
      id
      lineItems {
        ...BasketManagerLineItem
      }
    }
  }

  ${BASKET_MANAGER_LINE_ITEM}
`
