import Cookies from 'js-cookie'

import authTokenCookie from '../cookies/cookie'

import { API } from 'src/api'

type Config<K> = {
  key: K
  queryParamName?: string
  expires?: number
  headerTransform?: {
    key: string
    transformer: (value: string) => string
  }
}

function create<K extends string>({
  defaultGetValue,
  ...config
}: Config<K> & { defaultGetValue?: string }) {
  return {
    ...config,
    get(): string | undefined {
      return Cookies.get(config.key) || defaultGetValue
    },
    set(value: string | string[], expires?: number): void {
      Cookies.set(config.key, value.toString(), {
        expires,
      })
    },
    remove: () => {
      Cookies.remove(config.key)
    },
  }
}

const BASKET_ID = create<'basket-id'>({
  key: 'basket-id',
  expires: 7,
})

const AUTH_TOKEN = create<typeof authTokenCookie.name>({
  key: authTokenCookie.name,
  queryParamName: 'authToken',
  headerTransform: {
    key: 'authorization',
    transformer: (value) => `Bearer ${value}`,
  },
})

const MEMBERSHIP_INVOICE_DRAFT_ID = create<'membership-invoice-draft-id'>({
  key: 'membership-invoice-draft-id',
  expires: 7,
})

const CURRENCY_CODE = create<'currency-code'>({
  key: 'currency-code',
  defaultGetValue: API.CurrencyCode.USD,
  expires: 365,
})

const DISCOUNT_CODE = create<'discount-code'>({
  key: 'discount-code',
  expires: 7,
})

const BANNER_DISMISSED_ID = create<'banner-dismissed-id'>({
  key: 'banner-dismissed-id',
  expires: 365,
})

const MEMBERSHIP_PROMO_CODE = create<'membership-promo-code'>({
  key: 'membership-promo-code',
  queryParamName: 'mpc',
  expires: 7,
})

const MEMBERSHIP_AFFILIATE_SLUG = create<'membership-affiliate-slug'>({
  key: 'membership-affiliate-slug',
  queryParamName: 'partner',
  expires: 7,
})

const COOKIE_MANAGER = {
  [BASKET_ID.key]: BASKET_ID,
  [AUTH_TOKEN.key]: AUTH_TOKEN,
  [MEMBERSHIP_INVOICE_DRAFT_ID.key]: MEMBERSHIP_INVOICE_DRAFT_ID,
  [MEMBERSHIP_AFFILIATE_SLUG.key]: MEMBERSHIP_AFFILIATE_SLUG,
  [BANNER_DISMISSED_ID.key]: BANNER_DISMISSED_ID,
  [MEMBERSHIP_PROMO_CODE.key]: MEMBERSHIP_PROMO_CODE,
  [CURRENCY_CODE.key]: CURRENCY_CODE,
  [DISCOUNT_CODE.key]: DISCOUNT_CODE,
}

export default COOKIE_MANAGER
