import { gql } from '@apollo/client'

export const BASKET_MANAGER_LINE_ITEM = gql`
  fragment BasketManagerLineItem on BasketLineItem {
    id
    quantity
    variant {
      id
    }
    product {
      id
      slug
    }
  }
`
