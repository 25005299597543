import React from 'react'
import { buildUrl as buildImgixUrl, theme } from '@superhi/design'
import Head from 'next/head'

const DEFAULT_TITLE = 'Online code, design and project management courses'
const DEFAULT_DESCRIPTION =
  'Learn code, design and project management skills on flexible online courses. Join a global community learning &amp; growing together.'
const DEFAULT_IMAGE_URL = 'https://superhi.s3.amazonaws.com/assets/images/seo/default.jpg'
const IMGIX_EXCLUDE_URLS = ['https://embed-ssl.wistia.com/']

type Props = {
  title?: string
  description?: string
  imageUrl?: string
  imageFm?: Parameters<typeof buildImgixUrl>[1]['fm']
  noIndex?: boolean
}

const SEO: React.FC<React.PropsWithChildren<Props>> = ({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  imageUrl = DEFAULT_IMAGE_URL,
  imageFm = 'png8',
  noIndex = false,
}) => {
  const formattedImageUrl = React.useMemo(
    () =>
      imageUrl
        ? IMGIX_EXCLUDE_URLS.some((url) => imageUrl.startsWith(url))
          ? imageUrl
          : buildImgixUrl(imageUrl, {
              w: 1920,
              h: 1080,
              fit: 'crop',
              fm: imageFm,
            })
        : undefined,
    [imageUrl, imageFm],
  )

  const fixedTitle = `${title} – SuperHi`

  return (
    <Head>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <title>{fixedTitle}</title>
      {description && <meta key="description" name="description" content={description} />}
      <meta key="theme-color" name="theme-color" content={theme.colors.BLUE_50} />

      <meta key="fb:app_id" property="fb:app_id" content="141228133202413" />

      <meta key="twitter:title" name="twitter:title" content={fixedTitle} />
      <meta key="twitter:description" name="twitter:description" content={description} />
      <meta key="twitter:image" name="twitter:image" content={formattedImageUrl} />
      <meta key="twitter:site" name="twitter:site" content="@superhi_" />
      <meta key="twitter:card" name="twitter:card" content="summary_large_image" />

      <meta key="og:type" property="og:type" content="website" />
      <meta key="og:title" property="og:title" content={fixedTitle} />
      <meta key="og:description" property="og:description" content={description} />
      <meta key="og:image" property="og:image" content={formattedImageUrl} />
      <meta key="og:type" property="og:type" content="website" />
      {/* <meta property="og:url" content={canonicalUrl} /> */}

      {noIndex && <meta key="robots" name="robots" content="noindex" />}
    </Head>
  )
}

export default SEO
