import React from 'react'
import { SiteFooter as SiteFooterComponent, SelectInput } from '@superhi/design'
import { useQuery } from '@apollo/client'

import { useAppDispatch, useAppState } from '../StateProvider'

import { CURRENCIES } from './queries'
import { CurrencyCodeWrapper } from './styles'
import { ITEMS, SOCIAL_LINKS, BOTTOM_LINKS } from './config'

import COOKIE_MANAGER from 'src/cookies'
import { CurrencyCode } from 'src/api/types'
import { API } from 'src/api'

const SiteFooter = () => {
  const { data } = useQuery<API.SiteFooterComponentCurrencies>(CURRENCIES, {
    ssr: false,
  })
  const appState = useAppState()
  const appDispatch = useAppDispatch()

  const currencies = data?.currencies?.nodes || []

  return <SiteFooterComponent items={ITEMS} socialLinks={SOCIAL_LINKS} bottomLinks={BOTTOM_LINKS} />
}

export default SiteFooter
