import { FacebookPixel, GoogleAnalytics } from '../../../services'
import { getFacebookPixelContentCategory } from '../utils'

import { PRODUCT_ADDED } from './queries'

import initApollo, { API } from 'src/api'

export const productAdded = async (config: { variantId: string; quantity: number }) => {
  const client = initApollo({})

  try {
    const response = await client.query<API.UseEcomProductAdded, API.UseEcomProductAddedVariables>({
      query: PRODUCT_ADDED,
      variables: {
        id: config.variantId,
      },
    })

    const productVariant = response.data.productVariant

    if (productVariant) {
      // facebook pixel
      FacebookPixel.track('AddToCart', {
        content_type: 'product',
        content_ids: [productVariant.product.id],
        value: productVariant.priceConversion.value,
        contents: [
          {
            id: productVariant.product.id,
            quantity: config.quantity,
            item_price: productVariant.priceConversion.value,
          },
        ],
        content_name: productVariant.product.title,
        currency: productVariant.priceConversion.currencyCode,
        content_category: getFacebookPixelContentCategory([productVariant.product.__typename]),
      })

      GoogleAnalytics.track('add_to_cart', {
        currency: productVariant.priceConversion.currencyCode,
        items: [
          {
            id: productVariant.product.id,
            name: productVariant.product.title,
            quantity: config.quantity,
            price: productVariant.priceConversion.value,
            variant: productVariant.id,
            category:
              productVariant.product.__typename === 'ProductCourse'
                ? productVariant.product.difficultyLevel
                : undefined,
          },
        ],
      })
    }
  } catch (e) {}
}
