/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IsLoggedIn
// ====================================================

export interface IsLoggedIn_session {
  __typename: "Session";
  id: string;
  type: UserType;
  isAdmin: boolean;
}

export interface IsLoggedIn {
  /**
   * Get the session of the current user
   */
  session: IsLoggedIn_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BasketCreate
// ====================================================

export interface BasketCreate_basketCreate {
  __typename: "Basket";
  id: string;
}

export interface BasketCreate {
  /**
   * Create basket
   */
  basketCreate: BasketCreate_basketCreate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BasketLineItemsAdd
// ====================================================

export interface BasketLineItemsAdd_basketLineItemsAdd_lineItems_variant {
  __typename: "ProductVariant";
  id: string;
}

export interface BasketLineItemsAdd_basketLineItemsAdd_lineItems_product {
  __typename: "ProductBook" | "ProductCourse";
  id: string;
  slug: string;
}

export interface BasketLineItemsAdd_basketLineItemsAdd_lineItems {
  __typename: "BasketLineItem";
  id: string;
  quantity: number;
  variant: BasketLineItemsAdd_basketLineItemsAdd_lineItems_variant;
  product: BasketLineItemsAdd_basketLineItemsAdd_lineItems_product;
}

export interface BasketLineItemsAdd_basketLineItemsAdd {
  __typename: "Basket";
  id: string;
  lineItems: BasketLineItemsAdd_basketLineItemsAdd_lineItems[] | null;
}

export interface BasketLineItemsAdd {
  /**
   * Add line items to basket
   */
  basketLineItemsAdd: BasketLineItemsAdd_basketLineItemsAdd | null;
}

export interface BasketLineItemsAddVariables {
  variantIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BasketBasket
// ====================================================

export interface BasketBasket_basket_lineItems_variant {
  __typename: "ProductVariant";
  id: string;
}

export interface BasketBasket_basket_lineItems_product {
  __typename: "ProductBook" | "ProductCourse";
  id: string;
  slug: string;
}

export interface BasketBasket_basket_lineItems {
  __typename: "BasketLineItem";
  id: string;
  quantity: number;
  variant: BasketBasket_basket_lineItems_variant;
  product: BasketBasket_basket_lineItems_product;
}

export interface BasketBasket_basket {
  __typename: "Basket";
  id: string;
  lineItems: BasketBasket_basket_lineItems[] | null;
}

export interface BasketBasket {
  /**
   * Get a basket
   */
  basket: BasketBasket_basket | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteFooterComponentCurrencies
// ====================================================

export interface SiteFooterComponentCurrencies_currencies_nodes {
  __typename: "Currency";
  code: CurrencyCode;
  name: string;
}

export interface SiteFooterComponentCurrencies_currencies {
  __typename: "CurrencyList";
  nodes: SiteFooterComponentCurrencies_currencies_nodes[];
}

export interface SiteFooterComponentCurrencies {
  /**
   * Get all currencies
   */
  currencies: SiteFooterComponentCurrencies_currencies | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteHeaderComponentBasket
// ====================================================

export interface SiteHeaderComponentBasket_basket_lineItems {
  __typename: "BasketLineItem";
  id: string;
  quantity: number;
}

export interface SiteHeaderComponentBasket_basket {
  __typename: "Basket";
  lineItems: SiteHeaderComponentBasket_basket_lineItems[] | null;
}

export interface SiteHeaderComponentBasket {
  /**
   * Get a basket
   */
  basket: SiteHeaderComponentBasket_basket | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteHeaderComponentSettings
// ====================================================

export interface SiteHeaderComponentSettings_settings_announcement {
  __typename: "Announcement";
  id: string;
  body: string;
}

export interface SiteHeaderComponentSettings_settings {
  __typename: "Settings";
  announcement: SiteHeaderComponentSettings_settings_announcement | null;
}

export interface SiteHeaderComponentSettings {
  /**
   * Get the settings
   */
  settings: SiteHeaderComponentSettings_settings;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteHeaderComponentSession
// ====================================================

export interface SiteHeaderComponentSession_session_avatar {
  __typename: "Image";
  url: string;
}

export interface SiteHeaderComponentSession_session_membership {
  __typename: "UserMembership";
  id: string;
}

export interface SiteHeaderComponentSession_session {
  __typename: "Session";
  id: string;
  name: string | null;
  username: string;
  email: string;
  avatar: SiteHeaderComponentSession_session_avatar | null;
  membership: SiteHeaderComponentSession_session_membership | null;
}

export interface SiteHeaderComponentSession {
  /**
   * Get the session of the current user
   */
  session: SiteHeaderComponentSession_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UseTrackIdentity
// ====================================================

export interface UseTrackIdentity_session {
  __typename: "Session";
  id: string;
}

export interface UseTrackIdentity {
  /**
   * Get the session of the current user
   */
  session: UseTrackIdentity_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CourseCardComponent_price
// ====================================================

export interface CourseCardComponent_price_productCourse_lowestPrice {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CourseCardComponent_price_productCourse {
  __typename: "ProductCourse";
  id: string;
  lowestPrice: CourseCardComponent_price_productCourse_lowestPrice;
}

export interface CourseCardComponent_price {
  /**
   * Get a product course by it's id or slug
   */
  productCourse: CourseCardComponent_price_productCourse | null;
}

export interface CourseCardComponent_priceVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CourseCollectionCardComponent_price
// ====================================================

export interface CourseCollectionCardComponent_price_productCollection_price {
  __typename: "Money";
  currencyCode: CurrencyCode;
  value: number;
}

export interface CourseCollectionCardComponent_price_productCollection_fullPrice {
  __typename: "Money";
  currencyCode: CurrencyCode;
  value: number;
}

export interface CourseCollectionCardComponent_price_productCollection {
  __typename: "ProductCollection";
  id: string;
  price: CourseCollectionCardComponent_price_productCollection_price;
  fullPrice: CourseCollectionCardComponent_price_productCollection_fullPrice;
}

export interface CourseCollectionCardComponent_price {
  /**
   * Get product collection by id
   */
  productCollection: CourseCollectionCardComponent_price_productCollection | null;
}

export interface CourseCollectionCardComponent_priceVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LibraryGuideLeadCreate
// ====================================================

export interface LibraryGuideLeadCreate_leadCreate {
  __typename: "Lead";
  email: string;
}

export interface LibraryGuideLeadCreate {
  /**
   * Create a lead
   */
  leadCreate: LibraryGuideLeadCreate_leadCreate | null;
}

export interface LibraryGuideLeadCreateVariables {
  email: string;
  tag: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CourseCollectionsSection
// ====================================================

export interface CourseCollectionsSection_productCollections_nodes_image {
  __typename: "Image";
  url: string;
}

export interface CourseCollectionsSection_productCollections_nodes_price {
  __typename: "Money";
  currencyCode: CurrencyCode;
  value: number;
}

export interface CourseCollectionsSection_productCollections_nodes_fullPrice {
  __typename: "Money";
  currencyCode: CurrencyCode;
  value: number;
}

export interface CourseCollectionsSection_productCollections_nodes_variants_nodes_product_ProductBook {
  __typename: "ProductBook";
}

export interface CourseCollectionsSection_productCollections_nodes_variants_nodes_product_ProductCourse_image {
  __typename: "Image";
  url: string;
}

export interface CourseCollectionsSection_productCollections_nodes_variants_nodes_product_ProductCourse {
  __typename: "ProductCourse";
  id: string;
  slug: string;
  title: string;
  image: CourseCollectionsSection_productCollections_nodes_variants_nodes_product_ProductCourse_image;
  difficultyLevel: ProductCourseDifficultyLevel;
  length: ProductCourseLength;
}

export type CourseCollectionsSection_productCollections_nodes_variants_nodes_product = CourseCollectionsSection_productCollections_nodes_variants_nodes_product_ProductBook | CourseCollectionsSection_productCollections_nodes_variants_nodes_product_ProductCourse;

export interface CourseCollectionsSection_productCollections_nodes_variants_nodes {
  __typename: "ProductVariant";
  id: string;
  product: CourseCollectionsSection_productCollections_nodes_variants_nodes_product;
}

export interface CourseCollectionsSection_productCollections_nodes_variants {
  __typename: "ProductVariantList";
  nodes: CourseCollectionsSection_productCollections_nodes_variants_nodes[];
}

export interface CourseCollectionsSection_productCollections_nodes {
  __typename: "ProductCollection";
  id: string;
  title: string;
  description: string;
  image: CourseCollectionsSection_productCollections_nodes_image;
  price: CourseCollectionsSection_productCollections_nodes_price;
  fullPrice: CourseCollectionsSection_productCollections_nodes_fullPrice;
  slug: string;
  variants: CourseCollectionsSection_productCollections_nodes_variants | null;
}

export interface CourseCollectionsSection_productCollections {
  __typename: "ProductCollectionList";
  nodes: CourseCollectionsSection_productCollections_nodes[];
}

export interface CourseCollectionsSection {
  /**
   * Get all product collections
   */
  productCollections: CourseCollectionsSection_productCollections | null;
}

export interface CourseCollectionsSectionVariables {
  productCourseSlug?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CourseCollectionsSectionExtra
// ====================================================

export interface CourseCollectionsSectionExtra_productCollections_nodes_image {
  __typename: "Image";
  url: string;
}

export interface CourseCollectionsSectionExtra_productCollections_nodes_price {
  __typename: "Money";
  currencyCode: CurrencyCode;
  value: number;
}

export interface CourseCollectionsSectionExtra_productCollections_nodes_fullPrice {
  __typename: "Money";
  currencyCode: CurrencyCode;
  value: number;
}

export interface CourseCollectionsSectionExtra_productCollections_nodes_variants_nodes_product_ProductBook {
  __typename: "ProductBook";
}

export interface CourseCollectionsSectionExtra_productCollections_nodes_variants_nodes_product_ProductCourse_image {
  __typename: "Image";
  url: string;
}

export interface CourseCollectionsSectionExtra_productCollections_nodes_variants_nodes_product_ProductCourse {
  __typename: "ProductCourse";
  id: string;
  slug: string;
  title: string;
  image: CourseCollectionsSectionExtra_productCollections_nodes_variants_nodes_product_ProductCourse_image;
  difficultyLevel: ProductCourseDifficultyLevel;
  length: ProductCourseLength;
}

export type CourseCollectionsSectionExtra_productCollections_nodes_variants_nodes_product = CourseCollectionsSectionExtra_productCollections_nodes_variants_nodes_product_ProductBook | CourseCollectionsSectionExtra_productCollections_nodes_variants_nodes_product_ProductCourse;

export interface CourseCollectionsSectionExtra_productCollections_nodes_variants_nodes {
  __typename: "ProductVariant";
  id: string;
  product: CourseCollectionsSectionExtra_productCollections_nodes_variants_nodes_product;
}

export interface CourseCollectionsSectionExtra_productCollections_nodes_variants {
  __typename: "ProductVariantList";
  nodes: CourseCollectionsSectionExtra_productCollections_nodes_variants_nodes[];
}

export interface CourseCollectionsSectionExtra_productCollections_nodes {
  __typename: "ProductCollection";
  id: string;
  title: string;
  description: string;
  image: CourseCollectionsSectionExtra_productCollections_nodes_image;
  price: CourseCollectionsSectionExtra_productCollections_nodes_price;
  fullPrice: CourseCollectionsSectionExtra_productCollections_nodes_fullPrice;
  slug: string;
  variants: CourseCollectionsSectionExtra_productCollections_nodes_variants | null;
}

export interface CourseCollectionsSectionExtra_productCollections {
  __typename: "ProductCollectionList";
  nodes: CourseCollectionsSectionExtra_productCollections_nodes[];
}

export interface CourseCollectionsSectionExtra {
  /**
   * Get all product collections
   */
  productCollections: CourseCollectionsSectionExtra_productCollections | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Signup
// ====================================================

export interface Signup_leadCreate {
  __typename: "Lead";
  email: string;
}

export interface Signup {
  /**
   * Create a lead
   */
  leadCreate: Signup_leadCreate | null;
}

export interface SignupVariables {
  email: string;
  tag: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UseEcomCheckoutStartedBasket
// ====================================================

export interface UseEcomCheckoutStartedBasket_basket_value {
  __typename: "Money";
  value: number;
}

export interface UseEcomCheckoutStartedBasket_basket_discounts {
  __typename: "BasketDiscount";
  code: string;
}

export interface UseEcomCheckoutStartedBasket_basket_totalDiscount {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface UseEcomCheckoutStartedBasket_basket_total {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface UseEcomCheckoutStartedBasket_basket_lineItems_price {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface UseEcomCheckoutStartedBasket_basket_lineItems_variant {
  __typename: "ProductVariant";
  id: string;
}

export interface UseEcomCheckoutStartedBasket_basket_lineItems_product_ProductCourse_lowestPrice {
  __typename: "Money";
  currencyCode: CurrencyCode;
  value: number;
}

export interface UseEcomCheckoutStartedBasket_basket_lineItems_product_ProductCourse_image {
  __typename: "Image";
  url: string;
}

export interface UseEcomCheckoutStartedBasket_basket_lineItems_product_ProductCourse {
  __typename: "ProductCourse";
  id: string;
  slug: string;
  difficultyLevel: ProductCourseDifficultyLevel;
  title: string;
  lowestPrice: UseEcomCheckoutStartedBasket_basket_lineItems_product_ProductCourse_lowestPrice;
  image: UseEcomCheckoutStartedBasket_basket_lineItems_product_ProductCourse_image;
}

export interface UseEcomCheckoutStartedBasket_basket_lineItems_product_ProductBook_image {
  __typename: "Image";
  url: string;
}

export interface UseEcomCheckoutStartedBasket_basket_lineItems_product_ProductBook_lowestPrice {
  __typename: "Money";
  currencyCode: CurrencyCode;
  value: number;
}

export interface UseEcomCheckoutStartedBasket_basket_lineItems_product_ProductBook_variants_nodes_priceConversion {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface UseEcomCheckoutStartedBasket_basket_lineItems_product_ProductBook_variants_nodes {
  __typename: "ProductVariant";
  priceConversion: UseEcomCheckoutStartedBasket_basket_lineItems_product_ProductBook_variants_nodes_priceConversion;
}

export interface UseEcomCheckoutStartedBasket_basket_lineItems_product_ProductBook_variants {
  __typename: "ProductVariantList";
  nodes: UseEcomCheckoutStartedBasket_basket_lineItems_product_ProductBook_variants_nodes[];
}

export interface UseEcomCheckoutStartedBasket_basket_lineItems_product_ProductBook {
  __typename: "ProductBook";
  id: string;
  slug: string;
  title: string;
  image: UseEcomCheckoutStartedBasket_basket_lineItems_product_ProductBook_image;
  lowestPrice: UseEcomCheckoutStartedBasket_basket_lineItems_product_ProductBook_lowestPrice;
  variants: UseEcomCheckoutStartedBasket_basket_lineItems_product_ProductBook_variants;
}

export type UseEcomCheckoutStartedBasket_basket_lineItems_product = UseEcomCheckoutStartedBasket_basket_lineItems_product_ProductCourse | UseEcomCheckoutStartedBasket_basket_lineItems_product_ProductBook;

export interface UseEcomCheckoutStartedBasket_basket_lineItems {
  __typename: "BasketLineItem";
  quantity: number;
  price: UseEcomCheckoutStartedBasket_basket_lineItems_price;
  variant: UseEcomCheckoutStartedBasket_basket_lineItems_variant;
  product: UseEcomCheckoutStartedBasket_basket_lineItems_product;
}

export interface UseEcomCheckoutStartedBasket_basket {
  __typename: "Basket";
  id: string;
  value: UseEcomCheckoutStartedBasket_basket_value;
  discounts: UseEcomCheckoutStartedBasket_basket_discounts[] | null;
  totalDiscount: UseEcomCheckoutStartedBasket_basket_totalDiscount;
  total: UseEcomCheckoutStartedBasket_basket_total;
  lineItems: UseEcomCheckoutStartedBasket_basket_lineItems[] | null;
}

export interface UseEcomCheckoutStartedBasket {
  /**
   * Get a basket
   */
  basket: UseEcomCheckoutStartedBasket_basket | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UseEvents_LoggedIn_Session
// ====================================================

export interface UseEvents_LoggedIn_Session_session_team {
  __typename: "Team";
  id: string;
}

export interface UseEvents_LoggedIn_Session_session_membership {
  __typename: "UserMembership";
  id: string;
  status: MembershipStatus;
}

export interface UseEvents_LoggedIn_Session_session {
  __typename: "Session";
  id: string;
  name: string | null;
  email: string;
  type: UserType;
  team: UseEvents_LoggedIn_Session_session_team | null;
  membership: UseEvents_LoggedIn_Session_session_membership | null;
}

export interface UseEvents_LoggedIn_Session {
  /**
   * Get the session of the current user
   */
  session: UseEvents_LoggedIn_Session_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UseEcomOrderCompletedOrder
// ====================================================

export interface UseEcomOrderCompletedOrder_order_value {
  __typename: "MoneyV1";
  value: number;
}

export interface UseEcomOrderCompletedOrder_order_discounts_nodes {
  __typename: "OrderDiscount";
  code: string;
}

export interface UseEcomOrderCompletedOrder_order_discounts {
  __typename: "OrderDiscountList";
  nodes: UseEcomOrderCompletedOrder_order_discounts_nodes[];
}

export interface UseEcomOrderCompletedOrder_order_customerDetails {
  __typename: "OrderCustomerDetails";
  email: string;
}

export interface UseEcomOrderCompletedOrder_order_totalDiscount {
  __typename: "MoneyV1";
  value: number;
}

export interface UseEcomOrderCompletedOrder_order_total {
  __typename: "MoneyV1";
  value: number;
  currencyCode: CurrencyCodeV1;
}

export interface UseEcomOrderCompletedOrder_order_lineItems_nodes_price {
  __typename: "MoneyV1";
  value: number;
  currencyCode: CurrencyCodeV1;
}

export interface UseEcomOrderCompletedOrder_order_lineItems_nodes_variant_priceConversion {
  __typename: "Money";
  value: number;
}

export interface UseEcomOrderCompletedOrder_order_lineItems_nodes_variant {
  __typename: "ProductVariant";
  id: string;
  title: string;
  priceConversion: UseEcomOrderCompletedOrder_order_lineItems_nodes_variant_priceConversion;
}

export interface UseEcomOrderCompletedOrder_order_lineItems_nodes_product_ProductCourse_lowestPrice {
  __typename: "Money";
  currencyCode: CurrencyCode;
  value: number;
}

export interface UseEcomOrderCompletedOrder_order_lineItems_nodes_product_ProductCourse_image {
  __typename: "Image";
  url: string;
}

export interface UseEcomOrderCompletedOrder_order_lineItems_nodes_product_ProductCourse {
  __typename: "ProductCourse";
  id: string;
  slug: string;
  difficultyLevel: ProductCourseDifficultyLevel;
  title: string;
  lowestPrice: UseEcomOrderCompletedOrder_order_lineItems_nodes_product_ProductCourse_lowestPrice;
  image: UseEcomOrderCompletedOrder_order_lineItems_nodes_product_ProductCourse_image;
}

export interface UseEcomOrderCompletedOrder_order_lineItems_nodes_product_ProductBook_image {
  __typename: "Image";
  url: string;
}

export interface UseEcomOrderCompletedOrder_order_lineItems_nodes_product_ProductBook_lowestPrice {
  __typename: "Money";
  currencyCode: CurrencyCode;
  value: number;
}

export interface UseEcomOrderCompletedOrder_order_lineItems_nodes_product_ProductBook_variants_nodes_priceConversion {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface UseEcomOrderCompletedOrder_order_lineItems_nodes_product_ProductBook_variants_nodes {
  __typename: "ProductVariant";
  priceConversion: UseEcomOrderCompletedOrder_order_lineItems_nodes_product_ProductBook_variants_nodes_priceConversion;
}

export interface UseEcomOrderCompletedOrder_order_lineItems_nodes_product_ProductBook_variants {
  __typename: "ProductVariantList";
  nodes: UseEcomOrderCompletedOrder_order_lineItems_nodes_product_ProductBook_variants_nodes[];
}

export interface UseEcomOrderCompletedOrder_order_lineItems_nodes_product_ProductBook {
  __typename: "ProductBook";
  id: string;
  slug: string;
  title: string;
  image: UseEcomOrderCompletedOrder_order_lineItems_nodes_product_ProductBook_image;
  lowestPrice: UseEcomOrderCompletedOrder_order_lineItems_nodes_product_ProductBook_lowestPrice;
  variants: UseEcomOrderCompletedOrder_order_lineItems_nodes_product_ProductBook_variants;
}

export type UseEcomOrderCompletedOrder_order_lineItems_nodes_product = UseEcomOrderCompletedOrder_order_lineItems_nodes_product_ProductCourse | UseEcomOrderCompletedOrder_order_lineItems_nodes_product_ProductBook;

export interface UseEcomOrderCompletedOrder_order_lineItems_nodes {
  __typename: "OrderLineItem";
  quantity: number;
  price: UseEcomOrderCompletedOrder_order_lineItems_nodes_price;
  variant: UseEcomOrderCompletedOrder_order_lineItems_nodes_variant;
  product: UseEcomOrderCompletedOrder_order_lineItems_nodes_product;
}

export interface UseEcomOrderCompletedOrder_order_lineItems {
  __typename: "OrderLineItemList";
  nodes: UseEcomOrderCompletedOrder_order_lineItems_nodes[];
}

export interface UseEcomOrderCompletedOrder_order {
  __typename: "Order";
  id: string;
  value: UseEcomOrderCompletedOrder_order_value;
  discounts: UseEcomOrderCompletedOrder_order_discounts | null;
  customerDetails: UseEcomOrderCompletedOrder_order_customerDetails;
  totalDiscount: UseEcomOrderCompletedOrder_order_totalDiscount;
  total: UseEcomOrderCompletedOrder_order_total;
  lineItems: UseEcomOrderCompletedOrder_order_lineItems | null;
}

export interface UseEcomOrderCompletedOrder {
  /**
   * Get an order by it's id
   */
  order: UseEcomOrderCompletedOrder_order | null;
}

export interface UseEcomOrderCompletedOrderVariables {
  basketId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UseEcomProductAdded
// ====================================================

export interface UseEcomProductAdded_productVariant_priceConversion {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface UseEcomProductAdded_productVariant_product_ProductCourse_lowestPrice {
  __typename: "Money";
  currencyCode: CurrencyCode;
  value: number;
}

export interface UseEcomProductAdded_productVariant_product_ProductCourse_image {
  __typename: "Image";
  url: string;
}

export interface UseEcomProductAdded_productVariant_product_ProductCourse {
  __typename: "ProductCourse";
  id: string;
  slug: string;
  difficultyLevel: ProductCourseDifficultyLevel;
  title: string;
  lowestPrice: UseEcomProductAdded_productVariant_product_ProductCourse_lowestPrice;
  image: UseEcomProductAdded_productVariant_product_ProductCourse_image;
}

export interface UseEcomProductAdded_productVariant_product_ProductBook_image {
  __typename: "Image";
  url: string;
}

export interface UseEcomProductAdded_productVariant_product_ProductBook_lowestPrice {
  __typename: "Money";
  currencyCode: CurrencyCode;
  value: number;
}

export interface UseEcomProductAdded_productVariant_product_ProductBook_variants_nodes_priceConversion {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface UseEcomProductAdded_productVariant_product_ProductBook_variants_nodes {
  __typename: "ProductVariant";
  priceConversion: UseEcomProductAdded_productVariant_product_ProductBook_variants_nodes_priceConversion;
}

export interface UseEcomProductAdded_productVariant_product_ProductBook_variants {
  __typename: "ProductVariantList";
  nodes: UseEcomProductAdded_productVariant_product_ProductBook_variants_nodes[];
}

export interface UseEcomProductAdded_productVariant_product_ProductBook {
  __typename: "ProductBook";
  id: string;
  slug: string;
  title: string;
  image: UseEcomProductAdded_productVariant_product_ProductBook_image;
  lowestPrice: UseEcomProductAdded_productVariant_product_ProductBook_lowestPrice;
  variants: UseEcomProductAdded_productVariant_product_ProductBook_variants;
}

export type UseEcomProductAdded_productVariant_product = UseEcomProductAdded_productVariant_product_ProductCourse | UseEcomProductAdded_productVariant_product_ProductBook;

export interface UseEcomProductAdded_productVariant {
  __typename: "ProductVariant";
  id: string;
  priceConversion: UseEcomProductAdded_productVariant_priceConversion;
  product: UseEcomProductAdded_productVariant_product;
}

export interface UseEcomProductAdded {
  /**
   * Get product variant
   */
  productVariant: UseEcomProductAdded_productVariant | null;
}

export interface UseEcomProductAddedVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UseEcom_SubscriptionOrderCompleted_Invoice
// ====================================================

export interface UseEcom_SubscriptionOrderCompleted_Invoice_session_membership {
  __typename: "UserMembership";
  id: string;
  status: MembershipStatus;
}

export interface UseEcom_SubscriptionOrderCompleted_Invoice_session {
  __typename: "Session";
  id: string;
  email: string;
  type: UserType;
  membership: UseEcom_SubscriptionOrderCompleted_Invoice_session_membership | null;
}

export interface UseEcom_SubscriptionOrderCompleted_Invoice_membershipInvoice_value {
  __typename: "Money";
  value: number;
}

export interface UseEcom_SubscriptionOrderCompleted_Invoice_membershipInvoice_totalDiscount {
  __typename: "Money";
  value: number;
}

export interface UseEcom_SubscriptionOrderCompleted_Invoice_membershipInvoice_discounts_MembershipDiscountCoupon {
  __typename: "MembershipDiscountCoupon";
  stripeCouponId: string;
}

export interface UseEcom_SubscriptionOrderCompleted_Invoice_membershipInvoice_discounts_MembershipDiscountPromoCode_coupon {
  __typename: "MembershipDiscountCoupon";
  stripeCouponId: string;
}

export interface UseEcom_SubscriptionOrderCompleted_Invoice_membershipInvoice_discounts_MembershipDiscountPromoCode {
  __typename: "MembershipDiscountPromoCode";
  coupon: UseEcom_SubscriptionOrderCompleted_Invoice_membershipInvoice_discounts_MembershipDiscountPromoCode_coupon;
}

export type UseEcom_SubscriptionOrderCompleted_Invoice_membershipInvoice_discounts = UseEcom_SubscriptionOrderCompleted_Invoice_membershipInvoice_discounts_MembershipDiscountCoupon | UseEcom_SubscriptionOrderCompleted_Invoice_membershipInvoice_discounts_MembershipDiscountPromoCode;

export interface UseEcom_SubscriptionOrderCompleted_Invoice_membershipInvoice_total {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface UseEcom_SubscriptionOrderCompleted_Invoice_membershipInvoice_product_price {
  __typename: "StripePrice";
  id: string;
  value: number;
}

export interface UseEcom_SubscriptionOrderCompleted_Invoice_membershipInvoice_product {
  __typename: "MembershipProduct";
  id: string;
  title: string;
  price: UseEcom_SubscriptionOrderCompleted_Invoice_membershipInvoice_product_price;
}

export interface UseEcom_SubscriptionOrderCompleted_Invoice_membershipInvoice {
  __typename: "MembershipInvoice";
  id: string;
  value: UseEcom_SubscriptionOrderCompleted_Invoice_membershipInvoice_value;
  totalDiscount: UseEcom_SubscriptionOrderCompleted_Invoice_membershipInvoice_totalDiscount;
  discounts: UseEcom_SubscriptionOrderCompleted_Invoice_membershipInvoice_discounts[] | null;
  total: UseEcom_SubscriptionOrderCompleted_Invoice_membershipInvoice_total;
  product: UseEcom_SubscriptionOrderCompleted_Invoice_membershipInvoice_product;
}

export interface UseEcom_SubscriptionOrderCompleted_Invoice {
  /**
   * Get the session of the current user
   */
  session: UseEcom_SubscriptionOrderCompleted_Invoice_session | null;
  /**
   * Membership invoice
   */
  membershipInvoice: UseEcom_SubscriptionOrderCompleted_Invoice_membershipInvoice | null;
}

export interface UseEcom_SubscriptionOrderCompleted_InvoiceVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: About
// ====================================================

export interface About_team_nodes_image {
  __typename: "Image";
  url: string;
}

export interface About_team_nodes {
  __typename: "Person";
  id: string;
  description: string;
  facebook: string | null;
  image: About_team_nodes_image;
  instagram: string | null;
  jobTitle: string;
  location: string | null;
  name: string;
  twitter: string | null;
}

export interface About_team {
  __typename: "PersonList";
  nodes: About_team_nodes[];
}

export interface About {
  /**
   * Get all people
   */
  team: About_team | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Shopify
// ====================================================

export interface Shopify_productCourses_nodes_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Shopify_productCourses_nodes_lowestPrice {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface Shopify_productCourses_nodes {
  __typename: "ProductCourse";
  id: string;
  slug: string;
  title: string;
  image: Shopify_productCourses_nodes_image;
  isBeta: boolean;
  difficultyLevel: ProductCourseDifficultyLevel;
  releaseDate: any | null;
  shortDescription: string;
  lowestPrice: Shopify_productCourses_nodes_lowestPrice;
}

export interface Shopify_productCourses {
  __typename: "ProductCourseList";
  nodes: Shopify_productCourses_nodes[];
}

export interface Shopify {
  /**
   * Get all product courses
   */
  productCourses: Shopify_productCourses | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Book_Version2Signup
// ====================================================

export interface Book_Version2Signup_leadCreate {
  __typename: "Lead";
  email: string;
}

export interface Book_Version2Signup {
  /**
   * Create a lead
   */
  leadCreate: Book_Version2Signup_leadCreate | null;
}

export interface Book_Version2SignupVariables {
  email: string;
  tag: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Book
// ====================================================

export interface Book_productBook_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Book_productBook_contents {
  __typename: "ProductContent";
  title: string;
}

export interface Book_productBook {
  __typename: "ProductBook";
  id: string;
  description: string;
  image: Book_productBook_image;
  mailchimpTag: string;
  shortDescription: string;
  slug: string;
  title: string;
  contents: Book_productBook_contents[];
}

export interface Book {
  /**
   * Get a product book by it's id or slug
   */
  productBook: Book_productBook | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CartLineItemRemove
// ====================================================

export interface CartLineItemRemove_basketLineItemRemove_discounts {
  __typename: "BasketDiscount";
  code: string;
}

export interface CartLineItemRemove_basketLineItemRemove_lineItems_discount {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartLineItemRemove_basketLineItemRemove_lineItems_price {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartLineItemRemove_basketLineItemRemove_lineItems_product_image {
  __typename: "Image";
  url: string;
}

export interface CartLineItemRemove_basketLineItemRemove_lineItems_product {
  __typename: "ProductBook" | "ProductCourse";
  id: string;
  slug: string;
  image: CartLineItemRemove_basketLineItemRemove_lineItems_product_image;
}

export interface CartLineItemRemove_basketLineItemRemove_lineItems_variant_options {
  __typename: "ProductOption";
  name: string;
  value: string;
}

export interface CartLineItemRemove_basketLineItemRemove_lineItems_variant {
  __typename: "ProductVariant";
  id: string;
  maxQuantity: number;
  title: string;
  options: CartLineItemRemove_basketLineItemRemove_lineItems_variant_options[] | null;
}

export interface CartLineItemRemove_basketLineItemRemove_lineItems {
  __typename: "BasketLineItem";
  id: string;
  discount: CartLineItemRemove_basketLineItemRemove_lineItems_discount;
  price: CartLineItemRemove_basketLineItemRemove_lineItems_price;
  product: CartLineItemRemove_basketLineItemRemove_lineItems_product;
  quantity: number;
  variant: CartLineItemRemove_basketLineItemRemove_lineItems_variant;
}

export interface CartLineItemRemove_basketLineItemRemove_subtotal {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartLineItemRemove_basketLineItemRemove_total {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartLineItemRemove_basketLineItemRemove_totalDiscount {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartLineItemRemove_basketLineItemRemove_value {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartLineItemRemove_basketLineItemRemove {
  __typename: "Basket";
  id: string;
  discounts: CartLineItemRemove_basketLineItemRemove_discounts[] | null;
  requiresShipping: boolean;
  lineItems: CartLineItemRemove_basketLineItemRemove_lineItems[] | null;
  subtotal: CartLineItemRemove_basketLineItemRemove_subtotal;
  total: CartLineItemRemove_basketLineItemRemove_total;
  totalDiscount: CartLineItemRemove_basketLineItemRemove_totalDiscount;
  value: CartLineItemRemove_basketLineItemRemove_value;
}

export interface CartLineItemRemove {
  /**
   * Remove line item from basket
   */
  basketLineItemRemove: CartLineItemRemove_basketLineItemRemove | null;
}

export interface CartLineItemRemoveVariables {
  lineItemId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CartLineItemQuantityEdit
// ====================================================

export interface CartLineItemQuantityEdit_basketLineItemQuantityEdit_discounts {
  __typename: "BasketDiscount";
  code: string;
}

export interface CartLineItemQuantityEdit_basketLineItemQuantityEdit_lineItems_discount {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartLineItemQuantityEdit_basketLineItemQuantityEdit_lineItems_price {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartLineItemQuantityEdit_basketLineItemQuantityEdit_lineItems_product_image {
  __typename: "Image";
  url: string;
}

export interface CartLineItemQuantityEdit_basketLineItemQuantityEdit_lineItems_product {
  __typename: "ProductBook" | "ProductCourse";
  id: string;
  slug: string;
  image: CartLineItemQuantityEdit_basketLineItemQuantityEdit_lineItems_product_image;
}

export interface CartLineItemQuantityEdit_basketLineItemQuantityEdit_lineItems_variant_options {
  __typename: "ProductOption";
  name: string;
  value: string;
}

export interface CartLineItemQuantityEdit_basketLineItemQuantityEdit_lineItems_variant {
  __typename: "ProductVariant";
  id: string;
  maxQuantity: number;
  title: string;
  options: CartLineItemQuantityEdit_basketLineItemQuantityEdit_lineItems_variant_options[] | null;
}

export interface CartLineItemQuantityEdit_basketLineItemQuantityEdit_lineItems {
  __typename: "BasketLineItem";
  id: string;
  discount: CartLineItemQuantityEdit_basketLineItemQuantityEdit_lineItems_discount;
  price: CartLineItemQuantityEdit_basketLineItemQuantityEdit_lineItems_price;
  product: CartLineItemQuantityEdit_basketLineItemQuantityEdit_lineItems_product;
  quantity: number;
  variant: CartLineItemQuantityEdit_basketLineItemQuantityEdit_lineItems_variant;
}

export interface CartLineItemQuantityEdit_basketLineItemQuantityEdit_subtotal {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartLineItemQuantityEdit_basketLineItemQuantityEdit_total {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartLineItemQuantityEdit_basketLineItemQuantityEdit_totalDiscount {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartLineItemQuantityEdit_basketLineItemQuantityEdit_value {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartLineItemQuantityEdit_basketLineItemQuantityEdit {
  __typename: "Basket";
  id: string;
  discounts: CartLineItemQuantityEdit_basketLineItemQuantityEdit_discounts[] | null;
  requiresShipping: boolean;
  lineItems: CartLineItemQuantityEdit_basketLineItemQuantityEdit_lineItems[] | null;
  subtotal: CartLineItemQuantityEdit_basketLineItemQuantityEdit_subtotal;
  total: CartLineItemQuantityEdit_basketLineItemQuantityEdit_total;
  totalDiscount: CartLineItemQuantityEdit_basketLineItemQuantityEdit_totalDiscount;
  value: CartLineItemQuantityEdit_basketLineItemQuantityEdit_value;
}

export interface CartLineItemQuantityEdit {
  /**
   * Edit basket line item quantity
   */
  basketLineItemQuantityEdit: CartLineItemQuantityEdit_basketLineItemQuantityEdit | null;
}

export interface CartLineItemQuantityEditVariables {
  lineItemId: string;
  quantity: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UnlimitedMembership_CartCallout
// ====================================================

export interface UnlimitedMembership_CartCallout_membershipProduct_price {
  __typename: "StripePrice";
  value: number;
  currencyCode: CurrencyCode;
}

export interface UnlimitedMembership_CartCallout_membershipProduct {
  __typename: "MembershipProduct";
  id: string;
  price: UnlimitedMembership_CartCallout_membershipProduct_price;
}

export interface UnlimitedMembership_CartCallout {
  /**
   * Membership product by id
   */
  membershipProduct: UnlimitedMembership_CartCallout_membershipProduct | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CartBasket
// ====================================================

export interface CartBasket_basket_discounts {
  __typename: "BasketDiscount";
  code: string;
}

export interface CartBasket_basket_lineItems_discount {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartBasket_basket_lineItems_price {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartBasket_basket_lineItems_product_image {
  __typename: "Image";
  url: string;
}

export interface CartBasket_basket_lineItems_product {
  __typename: "ProductBook" | "ProductCourse";
  id: string;
  slug: string;
  image: CartBasket_basket_lineItems_product_image;
}

export interface CartBasket_basket_lineItems_variant_options {
  __typename: "ProductOption";
  name: string;
  value: string;
}

export interface CartBasket_basket_lineItems_variant {
  __typename: "ProductVariant";
  id: string;
  maxQuantity: number;
  title: string;
  options: CartBasket_basket_lineItems_variant_options[] | null;
}

export interface CartBasket_basket_lineItems {
  __typename: "BasketLineItem";
  id: string;
  discount: CartBasket_basket_lineItems_discount;
  price: CartBasket_basket_lineItems_price;
  product: CartBasket_basket_lineItems_product;
  quantity: number;
  variant: CartBasket_basket_lineItems_variant;
}

export interface CartBasket_basket_subtotal {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartBasket_basket_total {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartBasket_basket_totalDiscount {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartBasket_basket_value {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartBasket_basket {
  __typename: "Basket";
  id: string;
  discounts: CartBasket_basket_discounts[] | null;
  requiresShipping: boolean;
  lineItems: CartBasket_basket_lineItems[] | null;
  subtotal: CartBasket_basket_subtotal;
  total: CartBasket_basket_total;
  totalDiscount: CartBasket_basket_totalDiscount;
  value: CartBasket_basket_value;
}

export interface CartBasket {
  /**
   * Get a basket
   */
  basket: CartBasket_basket | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckoutCompleteSession
// ====================================================

export interface CheckoutCompleteSession_session {
  __typename: "Session";
  id: string;
}

export interface CheckoutCompleteSession {
  /**
   * Get the session of the current user
   */
  session: CheckoutCompleteSession_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckoutCompleteOrder
// ====================================================

export interface CheckoutCompleteOrder_order_giftDetails {
  __typename: "OrderGiftDetails";
  name: string;
}

export interface CheckoutCompleteOrder_order {
  __typename: "Order";
  id: string;
  giftDetails: CheckoutCompleteOrder_order_giftDetails | null;
}

export interface CheckoutCompleteOrder {
  /**
   * Get an order by it's id
   */
  order: CheckoutCompleteOrder_order | null;
}

export interface CheckoutCompleteOrderVariables {
  basketId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BasketAttemptConfirm
// ====================================================

export interface BasketAttemptConfirm_basketAttemptConfirm {
  __typename: "Basket";
  id: string;
  status: BasketStatus;
}

export interface BasketAttemptConfirm {
  /**
   * Attempt to confirm a basket
   */
  basketAttemptConfirm: BasketAttemptConfirm_basketAttemptConfirm | null;
}

export interface BasketAttemptConfirmVariables {
  basketId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BasketCheckoutExchange
// ====================================================

export interface BasketCheckoutExchange_basket_lineItems_variant {
  __typename: "ProductVariant";
  id: string;
  title: string;
}

export interface BasketCheckoutExchange_basket_lineItems_price {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface BasketCheckoutExchange_basket_lineItems {
  __typename: "BasketLineItem";
  id: string;
  variant: BasketCheckoutExchange_basket_lineItems_variant;
  quantity: number;
  price: BasketCheckoutExchange_basket_lineItems_price;
}

export interface BasketCheckoutExchange_basket_subtotal {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface BasketCheckoutExchange_basket_discounts {
  __typename: "BasketDiscount";
  code: string;
}

export interface BasketCheckoutExchange_basket_totalDeduction {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface BasketCheckoutExchange_basket_totalDiscount {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface BasketCheckoutExchange_basket_total {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface BasketCheckoutExchange_basket_value {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface BasketCheckoutExchange_basket {
  __typename: "Basket";
  id: string;
  status: BasketStatus;
  lineItems: BasketCheckoutExchange_basket_lineItems[] | null;
  subtotal: BasketCheckoutExchange_basket_subtotal;
  discounts: BasketCheckoutExchange_basket_discounts[] | null;
  totalDeduction: BasketCheckoutExchange_basket_totalDeduction;
  totalDiscount: BasketCheckoutExchange_basket_totalDiscount;
  total: BasketCheckoutExchange_basket_total;
  value: BasketCheckoutExchange_basket_value;
}

export interface BasketCheckoutExchange {
  /**
   * Get a basket
   */
  basket: BasketCheckoutExchange_basket | null;
}

export interface BasketCheckoutExchangeVariables {
  id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckoutStepsSummary
// ====================================================

export interface CheckoutStepsSummary_basket_lineItems_variant {
  __typename: "ProductVariant";
  id: string;
  title: string;
}

export interface CheckoutStepsSummary_basket_lineItems_price {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CheckoutStepsSummary_basket_lineItems {
  __typename: "BasketLineItem";
  id: string;
  variant: CheckoutStepsSummary_basket_lineItems_variant;
  quantity: number;
  price: CheckoutStepsSummary_basket_lineItems_price;
}

export interface CheckoutStepsSummary_basket_subtotal {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CheckoutStepsSummary_basket_discounts {
  __typename: "BasketDiscount";
  code: string;
}

export interface CheckoutStepsSummary_basket_totalDiscount {
  __typename: "Money";
  currencyCode: CurrencyCode;
  value: number;
}

export interface CheckoutStepsSummary_basket_total {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CheckoutStepsSummary_basket_value {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CheckoutStepsSummary_basket_customerDetails {
  __typename: "BasketCustomerDetails";
  name: string;
  email: string;
}

export interface CheckoutStepsSummary_basket_giftDetails {
  __typename: "BasketGiftDetails";
  name: string;
  email: string;
  message: string | null;
  date: any | null;
}

export interface CheckoutStepsSummary_basket_billingAddress {
  __typename: "BasketAddress";
  city: string;
  country: string;
  line1: string;
  line2: string | null;
  name: string;
  phoneNumber: string | null;
  postalCode: string;
  state: string;
}

export interface CheckoutStepsSummary_basket_shippingAddress {
  __typename: "BasketAddress";
  city: string;
  country: string;
  line1: string;
  line2: string | null;
  name: string;
  phoneNumber: string | null;
  postalCode: string;
  state: string;
}

export interface CheckoutStepsSummary_basket {
  __typename: "Basket";
  id: string;
  status: BasketStatus;
  requiresPayment: boolean;
  requiresShipping: boolean;
  lineItems: CheckoutStepsSummary_basket_lineItems[] | null;
  subtotal: CheckoutStepsSummary_basket_subtotal;
  discounts: CheckoutStepsSummary_basket_discounts[] | null;
  totalDiscount: CheckoutStepsSummary_basket_totalDiscount;
  total: CheckoutStepsSummary_basket_total;
  value: CheckoutStepsSummary_basket_value;
  customerDetails: CheckoutStepsSummary_basket_customerDetails | null;
  giftDetails: CheckoutStepsSummary_basket_giftDetails | null;
  referralSource: string | null;
  taxId: string | null;
  billingAddress: CheckoutStepsSummary_basket_billingAddress | null;
  shippingAddress: CheckoutStepsSummary_basket_shippingAddress | null;
}

export interface CheckoutStepsSummary {
  /**
   * Get a basket
   */
  basket: CheckoutStepsSummary_basket | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CheckoutStepsBillingBasketUpdate
// ====================================================

export interface CheckoutStepsBillingBasketUpdate_basketDetailsUpdate_billingAddress {
  __typename: "BasketAddress";
  city: string;
  country: string;
  line1: string;
  line2: string | null;
  name: string;
  phoneNumber: string | null;
  postalCode: string;
  state: string;
}

export interface CheckoutStepsBillingBasketUpdate_basketDetailsUpdate {
  __typename: "Basket";
  id: string;
  taxId: string | null;
  billingAddress: CheckoutStepsBillingBasketUpdate_basketDetailsUpdate_billingAddress | null;
}

export interface CheckoutStepsBillingBasketUpdate {
  /**
   * Update the basket details
   */
  basketDetailsUpdate: CheckoutStepsBillingBasketUpdate_basketDetailsUpdate | null;
}

export interface CheckoutStepsBillingBasketUpdateVariables {
  billingAddress: BasketAddressInput;
  taxId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckoutStepsBilling
// ====================================================

export interface CheckoutStepsBilling_basket_billingAddress {
  __typename: "BasketAddress";
  city: string;
  country: string;
  line1: string;
  line2: string | null;
  name: string;
  phoneNumber: string | null;
  postalCode: string;
  state: string;
}

export interface CheckoutStepsBilling_basket {
  __typename: "Basket";
  id: string;
  taxId: string | null;
  billingAddress: CheckoutStepsBilling_basket_billingAddress | null;
}

export interface CheckoutStepsBilling {
  /**
   * Get a basket
   */
  basket: CheckoutStepsBilling_basket | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CheckoutStepsInfoBasketUpdate
// ====================================================

export interface CheckoutStepsInfoBasketUpdate_basketDetailsUpdate_customerDetails {
  __typename: "BasketCustomerDetails";
  name: string;
  email: string;
}

export interface CheckoutStepsInfoBasketUpdate_basketDetailsUpdate_giftDetails {
  __typename: "BasketGiftDetails";
  name: string;
  email: string;
  message: string | null;
  date: any | null;
}

export interface CheckoutStepsInfoBasketUpdate_basketDetailsUpdate {
  __typename: "Basket";
  id: string;
  customerDetails: CheckoutStepsInfoBasketUpdate_basketDetailsUpdate_customerDetails | null;
  giftDetails: CheckoutStepsInfoBasketUpdate_basketDetailsUpdate_giftDetails | null;
  referralSource: string | null;
}

export interface CheckoutStepsInfoBasketUpdate {
  /**
   * Update the basket details
   */
  basketDetailsUpdate: CheckoutStepsInfoBasketUpdate_basketDetailsUpdate | null;
}

export interface CheckoutStepsInfoBasketUpdateVariables {
  customerDetails: BasketCustomerDetailsInput;
  giftDetails?: BasketGiftDetailsInput | null;
  referralSource: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckoutStepsInfo
// ====================================================

export interface CheckoutStepsInfo_basket_customerDetails {
  __typename: "BasketCustomerDetails";
  name: string;
  email: string;
}

export interface CheckoutStepsInfo_basket_giftDetails {
  __typename: "BasketGiftDetails";
  name: string;
  email: string;
  message: string | null;
  date: any | null;
}

export interface CheckoutStepsInfo_basket {
  __typename: "Basket";
  id: string;
  customerDetails: CheckoutStepsInfo_basket_customerDetails | null;
  giftDetails: CheckoutStepsInfo_basket_giftDetails | null;
  referralSource: string | null;
}

export interface CheckoutStepsInfo {
  /**
   * Get a basket
   */
  basket: CheckoutStepsInfo_basket | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckoutPaymentPayPal
// ====================================================

export interface CheckoutPaymentPayPal_basket_total {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CheckoutPaymentPayPal_basket {
  __typename: "Basket";
  id: string;
  total: CheckoutPaymentPayPal_basket_total;
  paypalOrderId: string;
}

export interface CheckoutPaymentPayPal {
  /**
   * Get a basket
   */
  basket: CheckoutPaymentPayPal_basket | null;
}

export interface CheckoutPaymentPayPalVariables {
  basketId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckoutPaymentStripe
// ====================================================

export interface CheckoutPaymentStripe_basket_total {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CheckoutPaymentStripe_basket {
  __typename: "Basket";
  id: string;
  total: CheckoutPaymentStripe_basket_total;
  stripePaymentIntentClientSecret: string;
}

export interface CheckoutPaymentStripe {
  /**
   * Get a basket
   */
  basket: CheckoutPaymentStripe_basket | null;
}

export interface CheckoutPaymentStripeVariables {
  basketId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BasketValidate
// ====================================================

export interface BasketValidate_basketValidate {
  __typename: "Basket";
  id: string;
  status: BasketStatus;
}

export interface BasketValidate {
  /**
   * Validate the basket
   */
  basketValidate: BasketValidate_basketValidate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckoutPaymentTotal
// ====================================================

export interface CheckoutPaymentTotal_basket_total {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CheckoutPaymentTotal_basket {
  __typename: "Basket";
  id: string;
  total: CheckoutPaymentTotal_basket_total;
}

export interface CheckoutPaymentTotal {
  /**
   * Get a basket
   */
  basket: CheckoutPaymentTotal_basket | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CheckoutStepsShippingBasketUpdate
// ====================================================

export interface CheckoutStepsShippingBasketUpdate_basketDetailsUpdate_shippingAddress {
  __typename: "BasketAddress";
  city: string;
  country: string;
  line1: string;
  line2: string | null;
  name: string;
  phoneNumber: string | null;
  postalCode: string;
  state: string;
}

export interface CheckoutStepsShippingBasketUpdate_basketDetailsUpdate {
  __typename: "Basket";
  id: string;
  shippingAddress: CheckoutStepsShippingBasketUpdate_basketDetailsUpdate_shippingAddress | null;
}

export interface CheckoutStepsShippingBasketUpdate {
  /**
   * Update the basket details
   */
  basketDetailsUpdate: CheckoutStepsShippingBasketUpdate_basketDetailsUpdate | null;
}

export interface CheckoutStepsShippingBasketUpdateVariables {
  shippingAddress: BasketAddressInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckoutStepsShipping
// ====================================================

export interface CheckoutStepsShipping_basket_shippingAddress {
  __typename: "BasketAddress";
  city: string;
  country: string;
  line1: string;
  line2: string | null;
  name: string;
  phoneNumber: string | null;
  postalCode: string;
  state: string;
}

export interface CheckoutStepsShipping_basket_billingAddress {
  __typename: "BasketAddress";
  city: string;
  country: string;
  line1: string;
  line2: string | null;
  name: string;
  phoneNumber: string | null;
  postalCode: string;
  state: string;
}

export interface CheckoutStepsShipping_basket {
  __typename: "Basket";
  id: string;
  shippingAddress: CheckoutStepsShipping_basket_shippingAddress | null;
  taxId: string | null;
  billingAddress: CheckoutStepsShipping_basket_billingAddress | null;
}

export interface CheckoutStepsShipping {
  /**
   * Get a basket
   */
  basket: CheckoutStepsShipping_basket | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Collections_collections
// ====================================================

export interface Collections_collections_productCollections_nodes_image {
  __typename: "Image";
  url: string;
}

export interface Collections_collections_productCollections_nodes_price {
  __typename: "Money";
  currencyCode: CurrencyCode;
  value: number;
}

export interface Collections_collections_productCollections_nodes_fullPrice {
  __typename: "Money";
  currencyCode: CurrencyCode;
  value: number;
}

export interface Collections_collections_productCollections_nodes_variants_nodes_product_ProductBook {
  __typename: "ProductBook";
}

export interface Collections_collections_productCollections_nodes_variants_nodes_product_ProductCourse_image {
  __typename: "Image";
  url: string;
}

export interface Collections_collections_productCollections_nodes_variants_nodes_product_ProductCourse {
  __typename: "ProductCourse";
  id: string;
  slug: string;
  title: string;
  image: Collections_collections_productCollections_nodes_variants_nodes_product_ProductCourse_image;
  difficultyLevel: ProductCourseDifficultyLevel;
  length: ProductCourseLength;
}

export type Collections_collections_productCollections_nodes_variants_nodes_product = Collections_collections_productCollections_nodes_variants_nodes_product_ProductBook | Collections_collections_productCollections_nodes_variants_nodes_product_ProductCourse;

export interface Collections_collections_productCollections_nodes_variants_nodes {
  __typename: "ProductVariant";
  id: string;
  product: Collections_collections_productCollections_nodes_variants_nodes_product;
}

export interface Collections_collections_productCollections_nodes_variants {
  __typename: "ProductVariantList";
  nodes: Collections_collections_productCollections_nodes_variants_nodes[];
}

export interface Collections_collections_productCollections_nodes {
  __typename: "ProductCollection";
  id: string;
  title: string;
  description: string;
  image: Collections_collections_productCollections_nodes_image;
  price: Collections_collections_productCollections_nodes_price;
  fullPrice: Collections_collections_productCollections_nodes_fullPrice;
  slug: string;
  variants: Collections_collections_productCollections_nodes_variants | null;
}

export interface Collections_collections_productCollections_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  totalCount: number;
}

export interface Collections_collections_productCollections {
  __typename: "ProductCollectionList";
  nodes: Collections_collections_productCollections_nodes[];
  totalCount: number;
  pageInfo: Collections_collections_productCollections_pageInfo;
}

export interface Collections_collections {
  /**
   * Get all product collections
   */
  productCollections: Collections_collections_productCollections | null;
}

export interface Collections_collectionsVariables {
  page?: number | null;
  perPage?: number | null;
  productCourseId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Collections
// ====================================================

export interface Collections_productCollections_nodes_variants_nodes_product_ProductBook {
  __typename: "ProductBook";
}

export interface Collections_productCollections_nodes_variants_nodes_product_ProductCourse {
  __typename: "ProductCourse";
  id: string;
  title: string;
}

export type Collections_productCollections_nodes_variants_nodes_product = Collections_productCollections_nodes_variants_nodes_product_ProductBook | Collections_productCollections_nodes_variants_nodes_product_ProductCourse;

export interface Collections_productCollections_nodes_variants_nodes {
  __typename: "ProductVariant";
  product: Collections_productCollections_nodes_variants_nodes_product;
}

export interface Collections_productCollections_nodes_variants {
  __typename: "ProductVariantList";
  nodes: Collections_productCollections_nodes_variants_nodes[];
}

export interface Collections_productCollections_nodes {
  __typename: "ProductCollection";
  variants: Collections_productCollections_nodes_variants | null;
}

export interface Collections_productCollections {
  __typename: "ProductCollectionList";
  nodes: Collections_productCollections_nodes[];
}

export interface Collections {
  /**
   * Get all product collections
   */
  productCollections: Collections_productCollections | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Course_CourseAccess
// ====================================================

export interface Course_CourseAccess_session_courses_nodes_course {
  __typename: "Course";
  id: string;
}

export interface Course_CourseAccess_session_courses_nodes {
  __typename: "UserCourse";
  id: string;
  course: Course_CourseAccess_session_courses_nodes_course;
}

export interface Course_CourseAccess_session_courses {
  __typename: "UserCourseList";
  nodes: Course_CourseAccess_session_courses_nodes[];
}

export interface Course_CourseAccess_session {
  __typename: "Session";
  id: string;
  isAdmin: boolean;
  type: UserType;
  courses: Course_CourseAccess_session_courses | null;
}

export interface Course_CourseAccess {
  /**
   * Get the session of the current user
   */
  session: Course_CourseAccess_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Course_pages
// ====================================================

export interface Course_pages_productCourses_nodes {
  __typename: "ProductCourse";
  id: string;
  slug: string;
}

export interface Course_pages_productCourses {
  __typename: "ProductCourseList";
  nodes: Course_pages_productCourses_nodes[];
}

export interface Course_pages {
  /**
   * Get all product courses
   */
  productCourses: Course_pages_productCourses | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Course_extra
// ====================================================

export interface Course_extra_topGuides_nodes_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Course_extra_topGuides_nodes {
  __typename: "LibraryGuide";
  slug: string;
  title: string;
  image: Course_extra_topGuides_nodes_image;
}

export interface Course_extra_topGuides {
  __typename: "LibraryGuideList";
  nodes: Course_extra_topGuides_nodes[];
}

export interface Course_extra_topPosts_nodes_LibraryPostArticle_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Course_extra_topPosts_nodes_LibraryPostArticle {
  __typename: "LibraryPostArticle";
  slug: string;
  type: LibraryPostType;
  title: string;
  image: Course_extra_topPosts_nodes_LibraryPostArticle_image;
  description: string;
}

export interface Course_extra_topPosts_nodes_LibraryPostVideo_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Course_extra_topPosts_nodes_LibraryPostVideo {
  __typename: "LibraryPostVideo";
  slug: string;
  type: LibraryPostType;
  title: string;
  duration: number;
  difficultyLevel: VideoDifficultyLevel;
  image: Course_extra_topPosts_nodes_LibraryPostVideo_image;
  description: string;
}

export type Course_extra_topPosts_nodes = Course_extra_topPosts_nodes_LibraryPostArticle | Course_extra_topPosts_nodes_LibraryPostVideo;

export interface Course_extra_topPosts {
  __typename: "LibraryPostList";
  nodes: Course_extra_topPosts_nodes[];
}

export interface Course_extra_otherCourses_nodes_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Course_extra_otherCourses_nodes_lowestPrice {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface Course_extra_otherCourses_nodes {
  __typename: "ProductCourse";
  id: string;
  slug: string;
  title: string;
  image: Course_extra_otherCourses_nodes_image;
  isBeta: boolean;
  difficultyLevel: ProductCourseDifficultyLevel;
  releaseDate: any | null;
  shortDescription: string;
  lowestPrice: Course_extra_otherCourses_nodes_lowestPrice;
}

export interface Course_extra_otherCourses {
  __typename: "ProductCourseList";
  nodes: Course_extra_otherCourses_nodes[];
}

export interface Course_extra {
  /**
   * Library guides
   */
  topGuides: Course_extra_topGuides | null;
  /**
   * Library posts
   */
  topPosts: Course_extra_topPosts | null;
  /**
   * Get all product courses
   */
  otherCourses: Course_extra_otherCourses | null;
}

export interface Course_extraVariables {
  otherCoursesSubject: ProductCourseSubject;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Course
// ====================================================

export interface Course_productCourse_image {
  __typename: "Image";
  url: string;
  width: number;
  height: number;
  blurhash: string;
}

export interface Course_productCourse_instructors_image {
  __typename: "Image";
  url: string;
}

export interface Course_productCourse_instructors {
  __typename: "Person";
  id: string;
  name: string;
  image: Course_productCourse_instructors_image;
  description: string;
  facebook: string | null;
  instagram: string | null;
  twitter: string | null;
}

export interface Course_productCourse_faqs {
  __typename: "HelpCenterArticle";
  id: string;
  title: string;
  body: string;
}

export interface Course_productCourse_lowestPrice {
  __typename: "Money";
  currencyCode: CurrencyCode;
  value: number;
}

export interface Course_productCourse_projects_ProductCourseProjectImage_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Course_productCourse_projects_ProductCourseProjectImage {
  __typename: "ProductCourseProjectImage";
  id: string;
  title: string;
  description: string;
  image: Course_productCourse_projects_ProductCourseProjectImage_image;
}

export interface Course_productCourse_projects_ProductCourseProjectVideo {
  __typename: "ProductCourseProjectVideo";
  id: string;
  title: string;
  description: string;
  videoId: string;
}

export type Course_productCourse_projects = Course_productCourse_projects_ProductCourseProjectImage | Course_productCourse_projects_ProductCourseProjectVideo;

export interface Course_productCourse_contents {
  __typename: "ProductContent";
  title: string;
  description: string | null;
}

export interface Course_productCourse_variants_nodes {
  __typename: "ProductVariant";
  id: string;
}

export interface Course_productCourse_variants {
  __typename: "ProductVariantList";
  nodes: Course_productCourse_variants_nodes[];
}

export interface Course_productCourse_course {
  __typename: "Course";
  id: string;
  slug: string;
  location: string;
}

export interface Course_productCourse {
  __typename: "ProductCourse";
  id: string;
  title: string;
  slug: string;
  isBeta: boolean;
  shortDescription: string;
  releaseDate: any | null;
  description: string;
  subjects: ProductCourseSubject[];
  status: ProductStatus;
  image: Course_productCourse_image;
  length: ProductCourseLength;
  overview: string[];
  instructors: Course_productCourse_instructors[] | null;
  faqs: Course_productCourse_faqs[] | null;
  lowestPrice: Course_productCourse_lowestPrice;
  projects: Course_productCourse_projects[] | null;
  syllabusUrl: string;
  mailchimpTag: string;
  difficultyLevel: ProductCourseDifficultyLevel;
  contents: Course_productCourse_contents[];
  requirements: string;
  targetAudience: string;
  variants: Course_productCourse_variants;
  course: Course_productCourse_course;
}

export interface Course {
  /**
   * Get a product course by it's id or slug
   */
  productCourse: Course_productCourse | null;
}

export interface CourseVariables {
  slug: string;
  statuses?: (ProductStatus | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CoursesCourses
// ====================================================

export interface CoursesCourses_productCourses_nodes_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface CoursesCourses_productCourses_nodes_lowestPrice {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CoursesCourses_productCourses_nodes {
  __typename: "ProductCourse";
  id: string;
  slug: string;
  title: string;
  image: CoursesCourses_productCourses_nodes_image;
  isBeta: boolean;
  difficultyLevel: ProductCourseDifficultyLevel;
  releaseDate: any | null;
  shortDescription: string;
  lowestPrice: CoursesCourses_productCourses_nodes_lowestPrice;
}

export interface CoursesCourses_productCourses_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  totalCount: number;
}

export interface CoursesCourses_productCourses {
  __typename: "ProductCourseList";
  nodes: CoursesCourses_productCourses_nodes[];
  totalCount: number;
  pageInfo: CoursesCourses_productCourses_pageInfo;
}

export interface CoursesCourses {
  /**
   * Get all product courses
   */
  productCourses: CoursesCourses_productCourses | null;
}

export interface CoursesCoursesVariables {
  page?: number | null;
  perPage?: number | null;
  difficultyLevel?: ProductCourseDifficultyLevel | null;
  length?: ProductCourseLength | null;
  subject?: ProductCourseSubject | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Discount_add
// ====================================================

export interface Discount_add_basketDiscountAdd {
  __typename: "Basket";
  id: string;
}

export interface Discount_add {
  /**
   * Add a discount to basket
   */
  basketDiscountAdd: Discount_add_basketDiscountAdd | null;
}

export interface Discount_addVariables {
  code?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Discount_pages
// ====================================================

export interface Discount_pages_discounts_nodes {
  __typename: "Discount";
  id: string;
  code: string;
  isActive: boolean;
  isPaused: boolean;
}

export interface Discount_pages_discounts {
  __typename: "DiscountList";
  nodes: Discount_pages_discounts_nodes[];
}

export interface Discount_pages {
  /**
   * Get all discounts
   */
  discounts: Discount_pages_discounts | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Discount
// ====================================================

export interface Discount_discount {
  __typename: "Discount";
  id: string;
  code: string;
  description: string;
  name: string;
  percent: number;
}

export interface Discount {
  /**
   * Get a discount
   */
  discount: Discount_discount | null;
}

export interface DiscountVariables {
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Editor
// ====================================================

export interface Editor_hallOfFame_nodes_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Editor_hallOfFame_nodes_users {
  __typename: "User";
  name: string | null;
  username: string;
}

export interface Editor_hallOfFame_nodes {
  __typename: "HallOfFamer";
  slug: string;
  title: string;
  image: Editor_hallOfFame_nodes_image;
  type: HallOfFamerType;
  users: Editor_hallOfFame_nodes_users[] | null;
}

export interface Editor_hallOfFame {
  __typename: "HallOfFamerList";
  nodes: Editor_hallOfFame_nodes[];
}

export interface Editor {
  /**
   * Hall of famers
   */
  hallOfFame: Editor_hallOfFame | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Generic
// ====================================================

export interface Generic_genericPage_seoImage {
  __typename: "Image";
  url: string;
}

export interface Generic_genericPage {
  __typename: "GenericPage";
  slug: string;
  title: string;
  body: string;
  seoDescription: string | null;
  seoImage: Generic_genericPage_seoImage | null;
  seoTitle: string | null;
}

export interface Generic {
  /**
   * Generic Page
   */
  genericPage: Generic_genericPage | null;
}

export interface GenericVariables {
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HallOfFameHome
// ====================================================

export interface HallOfFameHome_hallOfFamers_nodes_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface HallOfFameHome_hallOfFamers_nodes_users {
  __typename: "User";
  name: string | null;
  username: string;
}

export interface HallOfFameHome_hallOfFamers_nodes {
  __typename: "HallOfFamer";
  slug: string;
  title: string;
  image: HallOfFameHome_hallOfFamers_nodes_image;
  type: HallOfFamerType;
  users: HallOfFameHome_hallOfFamers_nodes_users[] | null;
}

export interface HallOfFameHome_hallOfFamers_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  totalCount: number;
}

export interface HallOfFameHome_hallOfFamers {
  __typename: "HallOfFamerList";
  nodes: HallOfFameHome_hallOfFamers_nodes[];
  totalCount: number;
  pageInfo: HallOfFameHome_hallOfFamers_pageInfo;
}

export interface HallOfFameHome {
  /**
   * Hall of famers
   */
  hallOfFamers: HallOfFameHome_hallOfFamers | null;
}

export interface HallOfFameHomeVariables {
  page?: number | null;
  perPage?: number | null;
  type?: HallOfFamerType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HallOfFamer_pages
// ====================================================

export interface HallOfFamer_pages_hallOfFamers_nodes {
  __typename: "HallOfFamer";
  id: string;
  slug: string;
}

export interface HallOfFamer_pages_hallOfFamers {
  __typename: "HallOfFamerList";
  nodes: HallOfFamer_pages_hallOfFamers_nodes[];
}

export interface HallOfFamer_pages {
  /**
   * Hall of famers
   */
  hallOfFamers: HallOfFamer_pages_hallOfFamers | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HallOfFamer
// ====================================================

export interface HallOfFamer_hallOfFamer_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface HallOfFamer_hallOfFamer_users_avatar {
  __typename: "Image";
  url: string;
}

export interface HallOfFamer_hallOfFamer_users {
  __typename: "User";
  id: string;
  name: string | null;
  username: string;
  avatar: HallOfFamer_hallOfFamer_users_avatar | null;
}

export interface HallOfFamer_hallOfFamer_next_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface HallOfFamer_hallOfFamer_next_users {
  __typename: "User";
  name: string | null;
  username: string;
}

export interface HallOfFamer_hallOfFamer_next_next_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface HallOfFamer_hallOfFamer_next_next_users {
  __typename: "User";
  name: string | null;
  username: string;
}

export interface HallOfFamer_hallOfFamer_next_next {
  __typename: "HallOfFamer";
  slug: string;
  title: string;
  image: HallOfFamer_hallOfFamer_next_next_image;
  type: HallOfFamerType;
  users: HallOfFamer_hallOfFamer_next_next_users[] | null;
}

export interface HallOfFamer_hallOfFamer_next {
  __typename: "HallOfFamer";
  slug: string;
  title: string;
  image: HallOfFamer_hallOfFamer_next_image;
  type: HallOfFamerType;
  users: HallOfFamer_hallOfFamer_next_users[] | null;
  next: HallOfFamer_hallOfFamer_next_next | null;
}

export interface HallOfFamer_hallOfFamer_previous_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface HallOfFamer_hallOfFamer_previous_users {
  __typename: "User";
  name: string | null;
  username: string;
}

export interface HallOfFamer_hallOfFamer_previous_previous_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface HallOfFamer_hallOfFamer_previous_previous_users {
  __typename: "User";
  name: string | null;
  username: string;
}

export interface HallOfFamer_hallOfFamer_previous_previous {
  __typename: "HallOfFamer";
  slug: string;
  title: string;
  image: HallOfFamer_hallOfFamer_previous_previous_image;
  type: HallOfFamerType;
  users: HallOfFamer_hallOfFamer_previous_previous_users[] | null;
}

export interface HallOfFamer_hallOfFamer_previous {
  __typename: "HallOfFamer";
  slug: string;
  title: string;
  image: HallOfFamer_hallOfFamer_previous_image;
  type: HallOfFamerType;
  users: HallOfFamer_hallOfFamer_previous_users[] | null;
  previous: HallOfFamer_hallOfFamer_previous_previous | null;
}

export interface HallOfFamer_hallOfFamer {
  __typename: "HallOfFamer";
  id: string;
  title: string;
  slug: string;
  image: HallOfFamer_hallOfFamer_image;
  type: HallOfFamerType;
  url: string | null;
  users: HallOfFamer_hallOfFamer_users[] | null;
  next: HallOfFamer_hallOfFamer_next | null;
  previous: HallOfFamer_hallOfFamer_previous | null;
}

export interface HallOfFamer {
  /**
   * Hall of famer
   */
  hallOfFamer: HallOfFamer_hallOfFamer | null;
}

export interface HallOfFamerVariables {
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HelpCenterArticle_pages
// ====================================================

export interface HelpCenterArticle_pages_helpCenterTopics_articles {
  __typename: "HelpCenterArticle";
  id: string;
  slug: string;
}

export interface HelpCenterArticle_pages_helpCenterTopics {
  __typename: "HelpCenterTopic";
  id: string;
  slug: string;
  articles: HelpCenterArticle_pages_helpCenterTopics_articles[];
}

export interface HelpCenterArticle_pages {
  /**
   * Help center topics
   */
  helpCenterTopics: HelpCenterArticle_pages_helpCenterTopics[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HelpCenterArticle
// ====================================================

export interface HelpCenterArticle_helpCenterTopics {
  __typename: "HelpCenterTopic";
  id: string;
  title: string;
  slug: string;
}

export interface HelpCenterArticle_helpCenterArticles_nodes {
  __typename: "HelpCenterArticle";
  id: string;
  slug: string;
  body: string;
  title: string;
}

export interface HelpCenterArticle_helpCenterArticles {
  __typename: "HelpCenterArticleList";
  nodes: HelpCenterArticle_helpCenterArticles_nodes[];
}

export interface HelpCenterArticle {
  /**
   * Help center topics
   */
  helpCenterTopics: HelpCenterArticle_helpCenterTopics[];
  /**
   * Help center articles
   */
  helpCenterArticles: HelpCenterArticle_helpCenterArticles | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HelpCenterTopic_pages
// ====================================================

export interface HelpCenterTopic_pages_helpCenterTopics {
  __typename: "HelpCenterTopic";
  id: string;
  slug: string;
}

export interface HelpCenterTopic_pages {
  /**
   * Help center topics
   */
  helpCenterTopics: HelpCenterTopic_pages_helpCenterTopics[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HelpCenterTopic
// ====================================================

export interface HelpCenterTopic_helpCenterTopics {
  __typename: "HelpCenterTopic";
  id: string;
  title: string;
  slug: string;
}

export interface HelpCenterTopic_helpCenterArticles_nodes {
  __typename: "HelpCenterArticle";
  id: string;
  slug: string;
  body: string;
  title: string;
}

export interface HelpCenterTopic_helpCenterArticles {
  __typename: "HelpCenterArticleList";
  nodes: HelpCenterTopic_helpCenterArticles_nodes[];
}

export interface HelpCenterTopic {
  /**
   * Help center topics
   */
  helpCenterTopics: HelpCenterTopic_helpCenterTopics[];
  /**
   * Help center articles
   */
  helpCenterArticles: HelpCenterTopic_helpCenterArticles | null;
}

export interface HelpCenterTopicVariables {
  topicSlug?: string | null;
  topicType?: HelpCenterTopicType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Home
// ====================================================

export interface Home_topCourses_nodes_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Home_topCourses_nodes_lowestPrice {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface Home_topCourses_nodes {
  __typename: "ProductCourse";
  id: string;
  slug: string;
  title: string;
  image: Home_topCourses_nodes_image;
  isBeta: boolean;
  difficultyLevel: ProductCourseDifficultyLevel;
  releaseDate: any | null;
  shortDescription: string;
  lowestPrice: Home_topCourses_nodes_lowestPrice;
}

export interface Home_topCourses {
  __typename: "ProductCourseList";
  nodes: Home_topCourses_nodes[];
}

export interface Home_topGuides_nodes_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Home_topGuides_nodes {
  __typename: "LibraryGuide";
  slug: string;
  title: string;
  image: Home_topGuides_nodes_image;
}

export interface Home_topGuides {
  __typename: "LibraryGuideList";
  nodes: Home_topGuides_nodes[];
}

export interface Home_hallOfFame_nodes_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Home_hallOfFame_nodes_users {
  __typename: "User";
  name: string | null;
  username: string;
}

export interface Home_hallOfFame_nodes {
  __typename: "HallOfFamer";
  slug: string;
  title: string;
  image: Home_hallOfFame_nodes_image;
  type: HallOfFamerType;
  users: Home_hallOfFame_nodes_users[] | null;
}

export interface Home_hallOfFame {
  __typename: "HallOfFamerList";
  nodes: Home_hallOfFame_nodes[];
}

export interface Home_faqs_nodes {
  __typename: "HelpCenterArticle";
  id: string;
  title: string;
  body: string;
}

export interface Home_faqs {
  __typename: "HelpCenterArticleList";
  nodes: Home_faqs_nodes[];
}

export interface Home {
  /**
   * Get all product courses
   */
  topCourses: Home_topCourses | null;
  /**
   * Library guides
   */
  topGuides: Home_topGuides | null;
  /**
   * Hall of famers
   */
  hallOfFame: Home_hallOfFame | null;
  /**
   * Help center articles
   */
  faqs: Home_faqs | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Job_pages
// ====================================================

export interface Job_pages_jobs_nodes {
  __typename: "Job";
  id: string;
  slug: string;
}

export interface Job_pages_jobs {
  __typename: "JobList";
  nodes: Job_pages_jobs_nodes[];
}

export interface Job_pages {
  /**
   * Jobs
   */
  jobs: Job_pages_jobs | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Job
// ====================================================

export interface Job_job {
  __typename: "Job";
  intro: string;
  location: string;
  publishedAt: any;
  slug: string;
  title: string;
  type: string;
  applicationUrl: string;
  body: string;
  closingAt: any;
}

export interface Job {
  /**
   * Job
   */
  job: Job_job | null;
}

export interface JobVariables {
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Jobs
// ====================================================

export interface Jobs_jobs_nodes {
  __typename: "Job";
  id: string;
  intro: string;
  location: string;
  publishedAt: any;
  slug: string;
  title: string;
}

export interface Jobs_jobs {
  __typename: "JobList";
  nodes: Jobs_jobs_nodes[];
}

export interface Jobs {
  /**
   * Jobs
   */
  jobs: Jobs_jobs | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LogIn_SessionCreate
// ====================================================

export interface LogIn_SessionCreate_sessionCreate {
  __typename: "Session";
  token: string | null;
  id: string;
  type: UserType;
  isAdmin: boolean;
}

export interface LogIn_SessionCreate {
  /**
   * Create a new session
   */
  sessionCreate: LogIn_SessionCreate_sessionCreate | null;
}

export interface LogIn_SessionCreateVariables {
  username: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignUp_AccountRegister
// ====================================================

export interface SignUp_AccountRegister_accountRegister {
  __typename: "Session";
  token: string | null;
  id: string;
  type: UserType;
  isAdmin: boolean;
}

export interface SignUp_AccountRegister {
  /**
   * Account register
   */
  accountRegister: SignUp_AccountRegister_accountRegister | null;
}

export interface SignUp_AccountRegisterVariables {
  username: string;
  email: string;
  name?: string | null;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MembershipCheckoutPayment_UserForm
// ====================================================

export interface MembershipCheckoutPayment_UserForm_session {
  __typename: "Session";
  id: string;
  name: string | null;
  email: string;
}

export interface MembershipCheckoutPayment_UserForm {
  /**
   * Get the session of the current user
   */
  session: MembershipCheckoutPayment_UserForm_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MembershipCheckout_Summary
// ====================================================

export interface MembershipCheckout_Summary_membershipInvoiceDraft_value {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface MembershipCheckout_Summary_membershipInvoiceDraft_total {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface MembershipCheckout_Summary_membershipInvoiceDraft_totalDiscount {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface MembershipCheckout_Summary_membershipInvoiceDraft_product {
  __typename: "MembershipProduct";
  id: string;
  title: string;
}

export interface MembershipCheckout_Summary_membershipInvoiceDraft_price_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
  intervalCount: number;
}

export interface MembershipCheckout_Summary_membershipInvoiceDraft_price {
  __typename: "StripePrice";
  id: string;
  value: number;
  recurring: MembershipCheckout_Summary_membershipInvoiceDraft_price_recurring | null;
}

export interface MembershipCheckout_Summary_membershipInvoiceDraft_discounts_MembershipDiscountCoupon_amountOff {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface MembershipCheckout_Summary_membershipInvoiceDraft_discounts_MembershipDiscountCoupon {
  __typename: "MembershipDiscountCoupon";
  name: string;
  duration: MembershipDiscountDuration;
  percentOff: number | null;
  durationInMonths: number | null;
  amountOff: MembershipCheckout_Summary_membershipInvoiceDraft_discounts_MembershipDiscountCoupon_amountOff | null;
}

export interface MembershipCheckout_Summary_membershipInvoiceDraft_discounts_MembershipDiscountPromoCode_coupon_amountOff {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface MembershipCheckout_Summary_membershipInvoiceDraft_discounts_MembershipDiscountPromoCode_coupon {
  __typename: "MembershipDiscountCoupon";
  name: string;
  duration: MembershipDiscountDuration;
  durationInMonths: number | null;
  percentOff: number | null;
  amountOff: MembershipCheckout_Summary_membershipInvoiceDraft_discounts_MembershipDiscountPromoCode_coupon_amountOff | null;
}

export interface MembershipCheckout_Summary_membershipInvoiceDraft_discounts_MembershipDiscountPromoCode {
  __typename: "MembershipDiscountPromoCode";
  code: string;
  coupon: MembershipCheckout_Summary_membershipInvoiceDraft_discounts_MembershipDiscountPromoCode_coupon;
}

export type MembershipCheckout_Summary_membershipInvoiceDraft_discounts = MembershipCheckout_Summary_membershipInvoiceDraft_discounts_MembershipDiscountCoupon | MembershipCheckout_Summary_membershipInvoiceDraft_discounts_MembershipDiscountPromoCode;

export interface MembershipCheckout_Summary_membershipInvoiceDraft {
  __typename: "MembershipInvoiceDraft";
  id: string;
  value: MembershipCheckout_Summary_membershipInvoiceDraft_value;
  total: MembershipCheckout_Summary_membershipInvoiceDraft_total;
  totalDiscount: MembershipCheckout_Summary_membershipInvoiceDraft_totalDiscount;
  product: MembershipCheckout_Summary_membershipInvoiceDraft_product;
  price: MembershipCheckout_Summary_membershipInvoiceDraft_price;
  discounts: MembershipCheckout_Summary_membershipInvoiceDraft_discounts[] | null;
}

export interface MembershipCheckout_Summary {
  /**
   * Membership invoice draft
   */
  membershipInvoiceDraft: MembershipCheckout_Summary_membershipInvoiceDraft | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MembershipCheckout_Terms
// ====================================================

export interface MembershipCheckout_Terms_membershipInvoiceDraft_total {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface MembershipCheckout_Terms_membershipInvoiceDraft_product {
  __typename: "MembershipProduct";
  id: string;
  title: string;
}

export interface MembershipCheckout_Terms_membershipInvoiceDraft_price_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
  intervalCount: number;
}

export interface MembershipCheckout_Terms_membershipInvoiceDraft_price {
  __typename: "StripePrice";
  id: string;
  recurring: MembershipCheckout_Terms_membershipInvoiceDraft_price_recurring | null;
}

export interface MembershipCheckout_Terms_membershipInvoiceDraft_renewal_total {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface MembershipCheckout_Terms_membershipInvoiceDraft_renewal {
  __typename: "MembershipInvoiceDraftRenewal";
  total: MembershipCheckout_Terms_membershipInvoiceDraft_renewal_total;
}

export interface MembershipCheckout_Terms_membershipInvoiceDraft_discounts_MembershipDiscountCoupon_amountOff {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface MembershipCheckout_Terms_membershipInvoiceDraft_discounts_MembershipDiscountCoupon {
  __typename: "MembershipDiscountCoupon";
  name: string;
  duration: MembershipDiscountDuration;
  percentOff: number | null;
  durationInMonths: number | null;
  amountOff: MembershipCheckout_Terms_membershipInvoiceDraft_discounts_MembershipDiscountCoupon_amountOff | null;
}

export interface MembershipCheckout_Terms_membershipInvoiceDraft_discounts_MembershipDiscountPromoCode_coupon_amountOff {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface MembershipCheckout_Terms_membershipInvoiceDraft_discounts_MembershipDiscountPromoCode_coupon {
  __typename: "MembershipDiscountCoupon";
  name: string;
  duration: MembershipDiscountDuration;
  durationInMonths: number | null;
  percentOff: number | null;
  amountOff: MembershipCheckout_Terms_membershipInvoiceDraft_discounts_MembershipDiscountPromoCode_coupon_amountOff | null;
}

export interface MembershipCheckout_Terms_membershipInvoiceDraft_discounts_MembershipDiscountPromoCode {
  __typename: "MembershipDiscountPromoCode";
  code: string;
  coupon: MembershipCheckout_Terms_membershipInvoiceDraft_discounts_MembershipDiscountPromoCode_coupon;
}

export type MembershipCheckout_Terms_membershipInvoiceDraft_discounts = MembershipCheckout_Terms_membershipInvoiceDraft_discounts_MembershipDiscountCoupon | MembershipCheckout_Terms_membershipInvoiceDraft_discounts_MembershipDiscountPromoCode;

export interface MembershipCheckout_Terms_membershipInvoiceDraft {
  __typename: "MembershipInvoiceDraft";
  id: string;
  total: MembershipCheckout_Terms_membershipInvoiceDraft_total;
  product: MembershipCheckout_Terms_membershipInvoiceDraft_product;
  price: MembershipCheckout_Terms_membershipInvoiceDraft_price;
  renewal: MembershipCheckout_Terms_membershipInvoiceDraft_renewal | null;
  discounts: MembershipCheckout_Terms_membershipInvoiceDraft_discounts[] | null;
}

export interface MembershipCheckout_Terms {
  /**
   * Membership invoice draft
   */
  membershipInvoiceDraft: MembershipCheckout_Terms_membershipInvoiceDraft | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MembershipInvoiceDraftValidate
// ====================================================

export interface MembershipInvoiceDraftValidate_membershipInvoiceDraftValidate {
  __typename: "MembershipInvoiceDraft";
  id: string;
}

export interface MembershipInvoiceDraftValidate {
  /**
   * Membership invoice draft validate
   */
  membershipInvoiceDraftValidate: MembershipInvoiceDraftValidate_membershipInvoiceDraftValidate | null;
}

export interface MembershipInvoiceDraftValidateVariables {
  userName: string;
  billingContact?: MembershipBillingContactInput | null;
  billingAddress?: MembershipBillingAddressInput | null;
  taxId?: TaxIdInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MembershipInvoiceDraftAttemptConfirm
// ====================================================

export interface MembershipInvoiceDraftAttemptConfirm_membershipInvoiceDraftAttemptConfirm_invoice {
  __typename: "MembershipInvoice";
  id: string;
}

export interface MembershipInvoiceDraftAttemptConfirm_membershipInvoiceDraftAttemptConfirm {
  __typename: "MembershipInvoiceDraft";
  id: string;
  status: MembershipInvoiceDraftStatus;
  invoice: MembershipInvoiceDraftAttemptConfirm_membershipInvoiceDraftAttemptConfirm_invoice | null;
}

export interface MembershipInvoiceDraftAttemptConfirm {
  /**
   * Membership invoice attempt confirm
   */
  membershipInvoiceDraftAttemptConfirm: MembershipInvoiceDraftAttemptConfirm_membershipInvoiceDraftAttemptConfirm | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MembershipInvoiceDraftCharge
// ====================================================

export interface MembershipInvoiceDraftCharge_membershipInvoiceDraftCharge_invoice {
  __typename: "MembershipInvoice";
  id: string;
}

export interface MembershipInvoiceDraftCharge_membershipInvoiceDraftCharge_paymentIntent {
  __typename: "MembershipInvoiceDraftPaymentIntent";
  status: MembershipInvoiceDraftPaymentIntentStatus;
  clientSecret: string;
}

export interface MembershipInvoiceDraftCharge_membershipInvoiceDraftCharge {
  __typename: "MembershipInvoiceDraft";
  id: string;
  status: MembershipInvoiceDraftStatus;
  invoice: MembershipInvoiceDraftCharge_membershipInvoiceDraftCharge_invoice | null;
  paymentIntent: MembershipInvoiceDraftCharge_membershipInvoiceDraftCharge_paymentIntent | null;
}

export interface MembershipInvoiceDraftCharge {
  /**
   * Membership invoice draft charge
   */
  membershipInvoiceDraftCharge: MembershipInvoiceDraftCharge_membershipInvoiceDraftCharge | null;
}

export interface MembershipInvoiceDraftChargeVariables {
  stripePaymentMethodId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MembershipCheckoutForm
// ====================================================

export interface MembershipCheckoutForm_session {
  __typename: "Session";
  id: string;
  name: string | null;
  email: string;
}

export interface MembershipCheckoutForm_membershipInvoiceDraft_total {
  __typename: "Money";
  value: number;
}

export interface MembershipCheckoutForm_membershipInvoiceDraft_product {
  __typename: "MembershipProduct";
  title: string;
}

export interface MembershipCheckoutForm_membershipInvoiceDraft_invoice {
  __typename: "MembershipInvoice";
  id: string;
}

export interface MembershipCheckoutForm_membershipInvoiceDraft_billingContact {
  __typename: "MembershipBillingContact";
  name: string;
  email: string;
}

export interface MembershipCheckoutForm_membershipInvoiceDraft_billingAddress {
  __typename: "MembershipBillingAddress";
  line1: string;
  line2: string | null;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

export interface MembershipCheckoutForm_membershipInvoiceDraft_taxId {
  __typename: "TaxId";
  type: TaxIdType;
  value: string;
}

export interface MembershipCheckoutForm_membershipInvoiceDraft {
  __typename: "MembershipInvoiceDraft";
  id: string;
  status: MembershipInvoiceDraftStatus;
  total: MembershipCheckoutForm_membershipInvoiceDraft_total;
  product: MembershipCheckoutForm_membershipInvoiceDraft_product;
  invoice: MembershipCheckoutForm_membershipInvoiceDraft_invoice | null;
  billingContact: MembershipCheckoutForm_membershipInvoiceDraft_billingContact | null;
  billingAddress: MembershipCheckoutForm_membershipInvoiceDraft_billingAddress | null;
  taxId: MembershipCheckoutForm_membershipInvoiceDraft_taxId | null;
}

export interface MembershipCheckoutForm {
  /**
   * Get the session of the current user
   */
  session: MembershipCheckoutForm_session | null;
  /**
   * Membership invoice draft
   */
  membershipInvoiceDraft: MembershipCheckoutForm_membershipInvoiceDraft | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MembershipCheckout_membershipInvoiceDraftCreate
// ====================================================

export interface MembershipCheckout_membershipInvoiceDraftCreate_membershipInvoiceDraftCreate {
  __typename: "MembershipInvoiceDraft";
  id: string;
}

export interface MembershipCheckout_membershipInvoiceDraftCreate {
  /**
   * Membership invoice draft create
   */
  membershipInvoiceDraftCreate: MembershipCheckout_membershipInvoiceDraftCreate_membershipInvoiceDraftCreate | null;
}

export interface MembershipCheckout_membershipInvoiceDraftCreateVariables {
  interval?: StripePriceRecurringInterval | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MembershipProduct_BuyInfo_MembershipProduct
// ====================================================

export interface MembershipProduct_BuyInfo_MembershipProduct_membershipProduct_prices_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
  intervalCount: number;
}

export interface MembershipProduct_BuyInfo_MembershipProduct_membershipProduct_prices {
  __typename: "StripePrice";
  id: string;
  value: number;
  currencyCode: CurrencyCode;
  recurring: MembershipProduct_BuyInfo_MembershipProduct_membershipProduct_prices_recurring | null;
}

export interface MembershipProduct_BuyInfo_MembershipProduct_membershipProduct {
  __typename: "MembershipProduct";
  id: string;
  prices: MembershipProduct_BuyInfo_MembershipProduct_membershipProduct_prices[];
}

export interface MembershipProduct_BuyInfo_MembershipProduct {
  /**
   * Membership product by id
   */
  membershipProduct: MembershipProduct_BuyInfo_MembershipProduct_membershipProduct | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MembershipProduct_BuyInfo_MembershipProductPriceDiscount
// ====================================================

export interface MembershipProduct_BuyInfo_MembershipProductPriceDiscount_membershipProductPriceDiscount_product {
  __typename: "MembershipProduct";
  id: string;
}

export interface MembershipProduct_BuyInfo_MembershipProductPriceDiscount_membershipProductPriceDiscount_priceDiscounted {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface MembershipProduct_BuyInfo_MembershipProductPriceDiscount_membershipProductPriceDiscount_discount_MembershipDiscountCoupon {
  __typename: "MembershipDiscountCoupon";
  duration: MembershipDiscountDuration;
  durationInMonths: number | null;
}

export interface MembershipProduct_BuyInfo_MembershipProductPriceDiscount_membershipProductPriceDiscount_discount_MembershipDiscountPromoCode_coupon {
  __typename: "MembershipDiscountCoupon";
  duration: MembershipDiscountDuration;
  durationInMonths: number | null;
}

export interface MembershipProduct_BuyInfo_MembershipProductPriceDiscount_membershipProductPriceDiscount_discount_MembershipDiscountPromoCode {
  __typename: "MembershipDiscountPromoCode";
  coupon: MembershipProduct_BuyInfo_MembershipProductPriceDiscount_membershipProductPriceDiscount_discount_MembershipDiscountPromoCode_coupon;
}

export type MembershipProduct_BuyInfo_MembershipProductPriceDiscount_membershipProductPriceDiscount_discount = MembershipProduct_BuyInfo_MembershipProductPriceDiscount_membershipProductPriceDiscount_discount_MembershipDiscountCoupon | MembershipProduct_BuyInfo_MembershipProductPriceDiscount_membershipProductPriceDiscount_discount_MembershipDiscountPromoCode;

export interface MembershipProduct_BuyInfo_MembershipProductPriceDiscount_membershipProductPriceDiscount {
  __typename: "MembershipProductPriceDiscount";
  product: MembershipProduct_BuyInfo_MembershipProductPriceDiscount_membershipProductPriceDiscount_product;
  priceDiscounted: MembershipProduct_BuyInfo_MembershipProductPriceDiscount_membershipProductPriceDiscount_priceDiscounted;
  discount: MembershipProduct_BuyInfo_MembershipProductPriceDiscount_membershipProductPriceDiscount_discount;
}

export interface MembershipProduct_BuyInfo_MembershipProductPriceDiscount {
  /**
   * membership_product_price_discount
   */
  membershipProductPriceDiscount: MembershipProduct_BuyInfo_MembershipProductPriceDiscount_membershipProductPriceDiscount | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MembershipProduct
// ====================================================

export interface MembershipProduct_faqs_nodes {
  __typename: "HelpCenterArticle";
  id: string;
  title: string;
  body: string;
}

export interface MembershipProduct_faqs {
  __typename: "HelpCenterArticleList";
  nodes: MembershipProduct_faqs_nodes[];
}

export interface MembershipProduct {
  /**
   * Help center articles
   */
  faqs: MembershipProduct_faqs | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MembershipProduct_Prices
// ====================================================

export interface MembershipProduct_Prices_course149_lowestPrice {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface MembershipProduct_Prices_course149 {
  __typename: "ProductCourse";
  id: string;
  lowestPrice: MembershipProduct_Prices_course149_lowestPrice;
}

export interface MembershipProduct_Prices_course499_lowestPrice {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface MembershipProduct_Prices_course499 {
  __typename: "ProductCourse";
  id: string;
  lowestPrice: MembershipProduct_Prices_course499_lowestPrice;
}

export interface MembershipProduct_Prices {
  /**
   * Get a product course by it's id or slug
   */
  course149: MembershipProduct_Prices_course149 | null;
  /**
   * Get a product course by it's id or slug
   */
  course499: MembershipProduct_Prices_course499 | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Partner_pages
// ====================================================

export interface Partner_pages_membershipAffiliates_nodes {
  __typename: "MembershipAffiliate";
  id: string;
  slug: string;
}

export interface Partner_pages_membershipAffiliates {
  __typename: "MembershipAffiliateList";
  nodes: Partner_pages_membershipAffiliates_nodes[];
}

export interface Partner_pages {
  /**
   * Membership affiliates
   */
  membershipAffiliates: Partner_pages_membershipAffiliates | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Partner
// ====================================================

export interface Partner_membershipAffiliate_coupon_amountOff {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface Partner_membershipAffiliate_coupon {
  __typename: "MembershipDiscountCoupon";
  name: string;
  duration: MembershipDiscountDuration;
  durationInMonths: number | null;
  percentOff: number | null;
  amountOff: Partner_membershipAffiliate_coupon_amountOff | null;
}

export interface Partner_membershipAffiliate {
  __typename: "MembershipAffiliate";
  id: string;
  slug: string;
  name: string;
  applicationFeePercent: number;
  coupon: Partner_membershipAffiliate_coupon | null;
}

export interface Partner_membershipProduct_price_recurring {
  __typename: "StripePriceRecurring";
  interval: StripePriceRecurringInterval;
  intervalCount: number;
}

export interface Partner_membershipProduct_price {
  __typename: "StripePrice";
  id: string;
  recurring: Partner_membershipProduct_price_recurring | null;
}

export interface Partner_membershipProduct {
  __typename: "MembershipProduct";
  id: string;
  price: Partner_membershipProduct_price;
}

export interface Partner {
  /**
   * Membership affiliate by id
   */
  membershipAffiliate: Partner_membershipAffiliate | null;
  /**
   * Membership product by id
   */
  membershipProduct: Partner_membershipProduct | null;
}

export interface PartnerVariables {
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Scholarship_pages
// ====================================================

export interface Scholarship_pages_scholarships_nodes {
  __typename: "Scholarship";
  id: string;
  slug: string;
}

export interface Scholarship_pages_scholarships {
  __typename: "ScholarshipList";
  nodes: Scholarship_pages_scholarships_nodes[];
}

export interface Scholarship_pages {
  /**
   * Get all scholarships
   */
  scholarships: Scholarship_pages_scholarships | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Scholarship
// ====================================================

export interface Scholarship_scholarship_image {
  __typename: "Image";
  url: string;
  width: number;
  height: number;
}

export interface Scholarship_scholarship {
  __typename: "Scholarship";
  applicationUrl: string;
  body: string;
  closingAt: any;
  id: string;
  image: Scholarship_scholarship_image;
  intro: string;
  publishedAt: any;
  slug: string;
  title: string;
  winnersNotifiedAt: any;
}

export interface Scholarship {
  /**
   * Get scholarship
   */
  scholarship: Scholarship_scholarship | null;
}

export interface ScholarshipVariables {
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Teams_SignUpModal_RegisterInterest
// ====================================================

export interface Teams_SignUpModal_RegisterInterest {
  /**
   * Register interest in teams
   */
  teamsRegisterInterest: boolean;
}

export interface Teams_SignUpModal_RegisterInterestVariables {
  name: string;
  from: string;
  body: string;
  subject: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Teams
// ====================================================

export interface Teams_faqs_nodes {
  __typename: "HelpCenterArticle";
  id: string;
  title: string;
  body: string;
}

export interface Teams_faqs {
  __typename: "HelpCenterArticleList";
  nodes: Teams_faqs_nodes[];
}

export interface Teams {
  /**
   * Help center articles
   */
  faqs: Teams_faqs | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AuthSession
// ====================================================

export interface AuthSession {
  __typename: "Session";
  id: string;
  type: UserType;
  isAdmin: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BasketManagerLineItem
// ====================================================

export interface BasketManagerLineItem_variant {
  __typename: "ProductVariant";
  id: string;
}

export interface BasketManagerLineItem_product {
  __typename: "ProductBook" | "ProductCourse";
  id: string;
  slug: string;
}

export interface BasketManagerLineItem {
  __typename: "BasketLineItem";
  id: string;
  quantity: number;
  variant: BasketManagerLineItem_variant;
  product: BasketManagerLineItem_product;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SessionSiteHeaderComponent
// ====================================================

export interface SessionSiteHeaderComponent_avatar {
  __typename: "Image";
  url: string;
}

export interface SessionSiteHeaderComponent_membership {
  __typename: "UserMembership";
  id: string;
}

export interface SessionSiteHeaderComponent {
  __typename: "Session";
  id: string;
  name: string | null;
  username: string;
  email: string;
  avatar: SessionSiteHeaderComponent_avatar | null;
  membership: SessionSiteHeaderComponent_membership | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CourseCardComponent
// ====================================================

export interface CourseCardComponent_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface CourseCardComponent_lowestPrice {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CourseCardComponent {
  __typename: "ProductCourse";
  id: string;
  slug: string;
  title: string;
  image: CourseCardComponent_image;
  isBeta: boolean;
  difficultyLevel: ProductCourseDifficultyLevel;
  releaseDate: any | null;
  shortDescription: string;
  lowestPrice: CourseCardComponent_lowestPrice;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CourseChipComponent
// ====================================================

export interface CourseChipComponent_image {
  __typename: "Image";
  url: string;
}

export interface CourseChipComponent {
  __typename: "ProductCourse";
  id: string;
  slug: string;
  title: string;
  image: CourseChipComponent_image;
  difficultyLevel: ProductCourseDifficultyLevel;
  length: ProductCourseLength;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CourseCollectionCardComponent
// ====================================================

export interface CourseCollectionCardComponent_image {
  __typename: "Image";
  url: string;
}

export interface CourseCollectionCardComponent_price {
  __typename: "Money";
  currencyCode: CurrencyCode;
  value: number;
}

export interface CourseCollectionCardComponent_fullPrice {
  __typename: "Money";
  currencyCode: CurrencyCode;
  value: number;
}

export interface CourseCollectionCardComponent_variants_nodes_product_ProductBook {
  __typename: "ProductBook";
}

export interface CourseCollectionCardComponent_variants_nodes_product_ProductCourse_image {
  __typename: "Image";
  url: string;
}

export interface CourseCollectionCardComponent_variants_nodes_product_ProductCourse {
  __typename: "ProductCourse";
  id: string;
  slug: string;
  title: string;
  image: CourseCollectionCardComponent_variants_nodes_product_ProductCourse_image;
  difficultyLevel: ProductCourseDifficultyLevel;
  length: ProductCourseLength;
}

export type CourseCollectionCardComponent_variants_nodes_product = CourseCollectionCardComponent_variants_nodes_product_ProductBook | CourseCollectionCardComponent_variants_nodes_product_ProductCourse;

export interface CourseCollectionCardComponent_variants_nodes {
  __typename: "ProductVariant";
  id: string;
  product: CourseCollectionCardComponent_variants_nodes_product;
}

export interface CourseCollectionCardComponent_variants {
  __typename: "ProductVariantList";
  nodes: CourseCollectionCardComponent_variants_nodes[];
}

export interface CourseCollectionCardComponent {
  __typename: "ProductCollection";
  id: string;
  title: string;
  description: string;
  image: CourseCollectionCardComponent_image;
  price: CourseCollectionCardComponent_price;
  fullPrice: CourseCollectionCardComponent_fullPrice;
  slug: string;
  variants: CourseCollectionCardComponent_variants | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InstructorComponent
// ====================================================

export interface InstructorComponent_image {
  __typename: "Image";
  url: string;
}

export interface InstructorComponent {
  __typename: "Person";
  id: string;
  description: string;
  facebook: string | null;
  image: InstructorComponent_image;
  instagram: string | null;
  jobTitle: string;
  location: string | null;
  name: string;
  twitter: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: JobCardComponent
// ====================================================

export interface JobCardComponent {
  __typename: "Job";
  id: string;
  intro: string;
  location: string;
  publishedAt: any;
  slug: string;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LibraryGuideCardComponent
// ====================================================

export interface LibraryGuideCardComponent_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface LibraryGuideCardComponent {
  __typename: "LibraryGuide";
  slug: string;
  title: string;
  image: LibraryGuideCardComponent_image;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LibraryPostArticleCardComponent
// ====================================================

export interface LibraryPostArticleCardComponent_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface LibraryPostArticleCardComponent {
  __typename: "LibraryPostArticle";
  slug: string;
  type: LibraryPostType;
  title: string;
  image: LibraryPostArticleCardComponent_image;
  description: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LibraryPostVideoCardComponent
// ====================================================

export interface LibraryPostVideoCardComponent_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface LibraryPostVideoCardComponent {
  __typename: "LibraryPostVideo";
  slug: string;
  type: LibraryPostType;
  title: string;
  duration: number;
  difficultyLevel: VideoDifficultyLevel;
  image: LibraryPostVideoCardComponent_image;
  description: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PersonComponent
// ====================================================

export interface PersonComponent_image {
  __typename: "Image";
  url: string;
}

export interface PersonComponent {
  __typename: "Person";
  id: string;
  description: string;
  facebook: string | null;
  image: PersonComponent_image;
  instagram: string | null;
  jobTitle: string;
  location: string | null;
  name: string;
  twitter: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: HallOfFameComponent
// ====================================================

export interface HallOfFameComponent_nodes_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface HallOfFameComponent_nodes_users {
  __typename: "User";
  name: string | null;
  username: string;
}

export interface HallOfFameComponent_nodes {
  __typename: "HallOfFamer";
  slug: string;
  title: string;
  image: HallOfFameComponent_nodes_image;
  type: HallOfFamerType;
  users: HallOfFameComponent_nodes_users[] | null;
}

export interface HallOfFameComponent {
  __typename: "HallOfFamerList";
  nodes: HallOfFameComponent_nodes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: HelpCenterArticlesComponent
// ====================================================

export interface HelpCenterArticlesComponent_nodes {
  __typename: "HelpCenterArticle";
  id: string;
  title: string;
  body: string;
}

export interface HelpCenterArticlesComponent {
  __typename: "HelpCenterArticleList";
  nodes: HelpCenterArticlesComponent_nodes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SuperLibraryGuidesComponent
// ====================================================

export interface SuperLibraryGuidesComponent_nodes_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface SuperLibraryGuidesComponent_nodes {
  __typename: "LibraryGuide";
  slug: string;
  title: string;
  image: SuperLibraryGuidesComponent_nodes_image;
}

export interface SuperLibraryGuidesComponent {
  __typename: "LibraryGuideList";
  nodes: SuperLibraryGuidesComponent_nodes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SuperLibraryPostsComponent
// ====================================================

export interface SuperLibraryPostsComponent_nodes_LibraryPostArticle_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface SuperLibraryPostsComponent_nodes_LibraryPostArticle {
  __typename: "LibraryPostArticle";
  slug: string;
  type: LibraryPostType;
  title: string;
  image: SuperLibraryPostsComponent_nodes_LibraryPostArticle_image;
  description: string;
}

export interface SuperLibraryPostsComponent_nodes_LibraryPostVideo_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface SuperLibraryPostsComponent_nodes_LibraryPostVideo {
  __typename: "LibraryPostVideo";
  slug: string;
  type: LibraryPostType;
  title: string;
  duration: number;
  difficultyLevel: VideoDifficultyLevel;
  image: SuperLibraryPostsComponent_nodes_LibraryPostVideo_image;
  description: string;
}

export type SuperLibraryPostsComponent_nodes = SuperLibraryPostsComponent_nodes_LibraryPostArticle | SuperLibraryPostsComponent_nodes_LibraryPostVideo;

export interface SuperLibraryPostsComponent {
  __typename: "LibraryPostList";
  nodes: SuperLibraryPostsComponent_nodes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TopCoursesComponent
// ====================================================

export interface TopCoursesComponent_nodes_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface TopCoursesComponent_nodes_lowestPrice {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface TopCoursesComponent_nodes {
  __typename: "ProductCourse";
  id: string;
  slug: string;
  title: string;
  image: TopCoursesComponent_nodes_image;
  isBeta: boolean;
  difficultyLevel: ProductCourseDifficultyLevel;
  releaseDate: any | null;
  shortDescription: string;
  lowestPrice: TopCoursesComponent_nodes_lowestPrice;
}

export interface TopCoursesComponent {
  __typename: "ProductCourseList";
  nodes: TopCoursesComponent_nodes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StudentWorkCardComponent
// ====================================================

export interface StudentWorkCardComponent_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface StudentWorkCardComponent_users {
  __typename: "User";
  name: string | null;
  username: string;
}

export interface StudentWorkCardComponent {
  __typename: "HallOfFamer";
  slug: string;
  title: string;
  image: StudentWorkCardComponent_image;
  type: HallOfFamerType;
  users: StudentWorkCardComponent_users[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventsProductCourse
// ====================================================

export interface EventsProductCourse_lowestPrice {
  __typename: "Money";
  currencyCode: CurrencyCode;
  value: number;
}

export interface EventsProductCourse_image {
  __typename: "Image";
  url: string;
}

export interface EventsProductCourse {
  __typename: "ProductCourse";
  id: string;
  slug: string;
  difficultyLevel: ProductCourseDifficultyLevel;
  title: string;
  lowestPrice: EventsProductCourse_lowestPrice;
  image: EventsProductCourse_image;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EventsProductBook
// ====================================================

export interface EventsProductBook_image {
  __typename: "Image";
  url: string;
}

export interface EventsProductBook_lowestPrice {
  __typename: "Money";
  currencyCode: CurrencyCode;
  value: number;
}

export interface EventsProductBook_variants_nodes_priceConversion {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface EventsProductBook_variants_nodes {
  __typename: "ProductVariant";
  priceConversion: EventsProductBook_variants_nodes_priceConversion;
}

export interface EventsProductBook_variants {
  __typename: "ProductVariantList";
  nodes: EventsProductBook_variants_nodes[];
}

export interface EventsProductBook {
  __typename: "ProductBook";
  id: string;
  slug: string;
  title: string;
  image: EventsProductBook_image;
  lowestPrice: EventsProductBook_lowestPrice;
  variants: EventsProductBook_variants;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TeamComponent
// ====================================================

export interface TeamComponent_nodes_image {
  __typename: "Image";
  url: string;
}

export interface TeamComponent_nodes {
  __typename: "Person";
  id: string;
  description: string;
  facebook: string | null;
  image: TeamComponent_nodes_image;
  instagram: string | null;
  jobTitle: string;
  location: string | null;
  name: string;
  twitter: string | null;
}

export interface TeamComponent {
  __typename: "PersonList";
  nodes: TeamComponent_nodes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CartBasket
// ====================================================

export interface CartBasket_discounts {
  __typename: "BasketDiscount";
  code: string;
}

export interface CartBasket_lineItems_discount {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartBasket_lineItems_price {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartBasket_lineItems_product_image {
  __typename: "Image";
  url: string;
}

export interface CartBasket_lineItems_product {
  __typename: "ProductBook" | "ProductCourse";
  id: string;
  slug: string;
  image: CartBasket_lineItems_product_image;
}

export interface CartBasket_lineItems_variant_options {
  __typename: "ProductOption";
  name: string;
  value: string;
}

export interface CartBasket_lineItems_variant {
  __typename: "ProductVariant";
  id: string;
  maxQuantity: number;
  title: string;
  options: CartBasket_lineItems_variant_options[] | null;
}

export interface CartBasket_lineItems {
  __typename: "BasketLineItem";
  id: string;
  discount: CartBasket_lineItems_discount;
  price: CartBasket_lineItems_price;
  product: CartBasket_lineItems_product;
  quantity: number;
  variant: CartBasket_lineItems_variant;
}

export interface CartBasket_subtotal {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartBasket_total {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartBasket_totalDiscount {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartBasket_value {
  __typename: "Money";
  value: number;
  currencyCode: CurrencyCode;
}

export interface CartBasket {
  __typename: "Basket";
  id: string;
  discounts: CartBasket_discounts[] | null;
  requiresShipping: boolean;
  lineItems: CartBasket_lineItems[] | null;
  subtotal: CartBasket_subtotal;
  total: CartBasket_total;
  totalDiscount: CartBasket_totalDiscount;
  value: CartBasket_value;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CheckoutStepsBillingFragment
// ====================================================

export interface CheckoutStepsBillingFragment_billingAddress {
  __typename: "BasketAddress";
  city: string;
  country: string;
  line1: string;
  line2: string | null;
  name: string;
  phoneNumber: string | null;
  postalCode: string;
  state: string;
}

export interface CheckoutStepsBillingFragment {
  __typename: "Basket";
  id: string;
  taxId: string | null;
  billingAddress: CheckoutStepsBillingFragment_billingAddress | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CheckoutStepsInfoFragment
// ====================================================

export interface CheckoutStepsInfoFragment_customerDetails {
  __typename: "BasketCustomerDetails";
  name: string;
  email: string;
}

export interface CheckoutStepsInfoFragment_giftDetails {
  __typename: "BasketGiftDetails";
  name: string;
  email: string;
  message: string | null;
  date: any | null;
}

export interface CheckoutStepsInfoFragment {
  __typename: "Basket";
  id: string;
  customerDetails: CheckoutStepsInfoFragment_customerDetails | null;
  giftDetails: CheckoutStepsInfoFragment_giftDetails | null;
  referralSource: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CheckoutStepsShippingFragment
// ====================================================

export interface CheckoutStepsShippingFragment_shippingAddress {
  __typename: "BasketAddress";
  city: string;
  country: string;
  line1: string;
  line2: string | null;
  name: string;
  phoneNumber: string | null;
  postalCode: string;
  state: string;
}

export interface CheckoutStepsShippingFragment {
  __typename: "Basket";
  id: string;
  shippingAddress: CheckoutStepsShippingFragment_shippingAddress | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SyllabusListProjectImageComponent
// ====================================================

export interface SyllabusListProjectImageComponent_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface SyllabusListProjectImageComponent {
  __typename: "ProductCourseProjectImage";
  id: string;
  title: string;
  description: string;
  image: SyllabusListProjectImageComponent_image;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SyllabusListProjectVideoComponent
// ====================================================

export interface SyllabusListProjectVideoComponent {
  __typename: "ProductCourseProjectVideo";
  id: string;
  title: string;
  description: string;
  videoId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Basket status
 */
export enum BasketStatus {
  COMPLETE = "COMPLETE",
  IN_PROGRESS = "IN_PROGRESS",
}

/**
 * Currency Code
 * 
 * The available currency codes
 */
export enum CurrencyCode {
  AUD = "AUD",
  CAD = "CAD",
  CHF = "CHF",
  DKK = "DKK",
  EUR = "EUR",
  GBP = "GBP",
  HKD = "HKD",
  JPY = "JPY",
  NOK = "NOK",
  NZD = "NZD",
  SEK = "SEK",
  SGD = "SGD",
  USD = "USD",
}

/**
 * Currency Codes for historical ordeers
 */
export enum CurrencyCodeV1 {
  ARS = "ARS",
  AUD = "AUD",
  BRL = "BRL",
  CAD = "CAD",
  CHF = "CHF",
  CZK = "CZK",
  DKK = "DKK",
  EUR = "EUR",
  GBP = "GBP",
  HKD = "HKD",
  ILS = "ILS",
  JPY = "JPY",
  KRW = "KRW",
  MXN = "MXN",
  NOK = "NOK",
  NZD = "NZD",
  PLN = "PLN",
  SEK = "SEK",
  SGD = "SGD",
  USD = "USD",
  ZAR = "ZAR",
}

/**
 * Hall of famer type
 */
export enum HallOfFamerType {
  DESIGN = "DESIGN",
  WEBSITE = "WEBSITE",
}

/**
 * Help center topic type
 */
export enum HelpCenterTopicType {
  NORMAL = "NORMAL",
  PRODUCT_COURSE = "PRODUCT_COURSE",
}

/**
 * Library item type
 */
export enum LibraryPostType {
  ADVICE = "ADVICE",
  ARTICLE = "ARTICLE",
  INTERVIEW = "INTERVIEW",
  TUTORIAL = "TUTORIAL",
}

/**
 * Membership product price discount duration
 */
export enum MembershipDiscountDuration {
  FOREVER = "FOREVER",
  ONCE = "ONCE",
  REPEATING = "REPEATING",
}

/**
 * Membership invoice draft payment intent status
 */
export enum MembershipInvoiceDraftPaymentIntentStatus {
  REQUIRES_ACTION = "REQUIRES_ACTION",
  REQUIRES_CAPTURE = "REQUIRES_CAPTURE",
  REQUIRES_CONFIRMATION = "REQUIRES_CONFIRMATION",
  REQUIRES_PAYMENT_METHOD = "REQUIRES_PAYMENT_METHOD",
}

/**
 * Membership invoice draft status
 */
export enum MembershipInvoiceDraftStatus {
  COMPLETE = "COMPLETE",
  IN_PROGRESS = "IN_PROGRESS",
}

/**
 * Membership status
 */
export enum MembershipStatus {
  ACTIVE = "ACTIVE",
  DEFAULTED = "DEFAULTED",
  INACTIVE = "INACTIVE",
  TRIALING = "TRIALING",
}

/**
 * Product course difficulty level
 */
export enum ProductCourseDifficultyLevel {
  ADVANCED = "ADVANCED",
  BEGINNER = "BEGINNER",
  INTERMEDIATE = "INTERMEDIATE",
}

/**
 * Product Course Length
 */
export enum ProductCourseLength {
  EIGHT_WEEKS = "EIGHT_WEEKS",
  FOUR_SIX_WEEKS = "FOUR_SIX_WEEKS",
  SHORT = "SHORT",
}

/**
 * Product course subject
 */
export enum ProductCourseSubject {
  CODE = "CODE",
  DESIGN = "DESIGN",
  PROJECT_MANAGEMENT = "PROJECT_MANAGEMENT",
}

/**
 * Product Status
 */
export enum ProductStatus {
  DRAFT = "DRAFT",
  LIVE = "LIVE",
}

/**
 * Stripe price recurring interval
 */
export enum StripePriceRecurringInterval {
  DAY = "DAY",
  MONTH = "MONTH",
  WEEK = "WEEK",
  YEAR = "YEAR",
}

/**
 * Tax type
 */
export enum TaxIdType {
  AE_TRN = "AE_TRN",
  AU_ABN = "AU_ABN",
  BR_CNPJ = "BR_CNPJ",
  BR_CPF = "BR_CPF",
  CA_BN = "CA_BN",
  CA_QST = "CA_QST",
  CH_VAT = "CH_VAT",
  CL_TIN = "CL_TIN",
  ES_CIF = "ES_CIF",
  EU_VAT = "EU_VAT",
  GB_VAT = "GB_VAT",
  HK_BR = "HK_BR",
  ID_NPWP = "ID_NPWP",
  IN_GST = "IN_GST",
  JP_CN = "JP_CN",
  JP_RN = "JP_RN",
  KR_BRN = "KR_BRN",
  LI_UID = "LI_UID",
  MX_RFC = "MX_RFC",
  MY_FRP = "MY_FRP",
  MY_ITN = "MY_ITN",
  MY_SST = "MY_SST",
  NO_VAT = "NO_VAT",
  NZ_GST = "NZ_GST",
  RU_INN = "RU_INN",
  RU_KPP = "RU_KPP",
  SA_VAT = "SA_VAT",
  SG_GST = "SG_GST",
  SG_UEN = "SG_UEN",
  TH_VAT = "TH_VAT",
  TW_VAT = "TW_VAT",
  US_EIN = "US_EIN",
  ZA_VAT = "ZA_VAT",
}

/**
 * User Type
 */
export enum UserType {
  FREE = "FREE",
  MEMBER = "MEMBER",
  STUDENT = "STUDENT",
  TEAM_MEMBER = "TEAM_MEMBER",
}

/**
 * Video difficulty level
 */
export enum VideoDifficultyLevel {
  ADVANCED = "ADVANCED",
  BEGINNER = "BEGINNER",
  INTERMEDIATE = "INTERMEDIATE",
}

/**
 * Basket address
 */
export interface BasketAddressInput {
  city: string;
  country: string;
  line1: string;
  line2?: string | null;
  name: string;
  phoneNumber?: string | null;
  postalCode: string;
  state: string;
}

/**
 * Basket customer details
 */
export interface BasketCustomerDetailsInput {
  email: string;
  name: string;
}

/**
 * Basket gift details
 */
export interface BasketGiftDetailsInput {
  date?: any | null;
  email: string;
  message?: string | null;
  name: string;
}

export interface MembershipBillingAddressInput {
  city: string;
  country: string;
  line1: string;
  line2?: string | null;
  postalCode: string;
  state: string;
}

export interface MembershipBillingContactInput {
  email: string;
  name: string;
}

/**
 * Tax id
 */
export interface TaxIdInput {
  type: TaxIdType;
  value: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
