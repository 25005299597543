import { setContext } from 'apollo-link-context'
import fetch from 'isomorphic-unfetch'
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
// import { BatchHttpLink } from "@apollo/client/link/batch-http";

import { destroyBasket } from '../basket'
import COOKIE_MANAGER from '../cookies'
import { removeUndefined } from '../utils'

import * as API from './types'

import { API_DOMAIN, API_VERSION } from 'src/config'
import { getAllClientCookies, transformCookiesToHeaders } from 'src/cookies/utils'

const IS_BROWSER = (process as any).browser

let client: ApolloClient<any> | null = null

const uri = `${API_DOMAIN}/v${API_VERSION}/graphql`

if (!IS_BROWSER) {
  ;(global as any).fetch = fetch
}

const getCookies = (
  cookies: { [key: string]: string } = {},
): { [key: string]: string | undefined } => ({
  ...removeUndefined(cookies),
  ...removeUndefined(getAllClientCookies()),
  'superhi-ci-token': process.env.SUPER_HI_CI_TOKEN,
})

const createAuthLink = (cookies?: { [key: string]: string }): any =>
  setContext((_, { headers = {} }) => {
    const parsedCookies = getCookies(cookies)
    const extraHeaders = transformCookiesToHeaders(parsedCookies)

    return {
      headers: {
        ...headers,
        ...extraHeaders,
      },
    }
  })

const errorLink = onError(({ operation, response, graphQLErrors = [] }) => {
  graphQLErrors.forEach((e) => {
    const error = e as unknown as { details: string; message: string }

    switch (error.details) {
      case 'basket': {
        if (error.message === 'Not found') {
          // console.log('not found');

          const context = operation.getContext()

          if (context.headers[COOKIE_MANAGER['basket-id'].key]) {
            if (client) {
              destroyBasket(client)
            }
          }
        }

        break
      }

      case 'base': {
        if (error.message === 'Access denied') {
          console.log(operation.getContext())
          console.log(JSON.stringify(operation.query))
          console.log('xoxoxox', error, operation, graphQLErrors)
          if (response) {
            ;(response as any).errors = null
          }
        }
        break
      }
    }

    // console.log(e);
    // if (error.details === '' && error.message === 'Not found') {
    //   removeBasketId();
    // }
  })

  // if (networkError) console.log(`[Network error]: ${networkError}`);
})

// const httpLink = new BatchHttpLink({
//   uri,
//   batchMax: 5, // No more than 5 operations per batch
//   batchInterval: 20, // Wait no more than 20ms after first batched operation
//   fetch,
// });

const httpLink = createHttpLink({
  uri,
  fetch,
})

if (!IS_BROWSER) {
  ;(global as any).fetch = fetch
}

const createClient = (initialState: any, cookies?: { [key: string]: string }, ssrMode = false) => {
  const cache = new InMemoryCache({
    typePolicies: {
      Money: {
        merge: true,
      },
      UserMembershipPlan: {
        merge: true,
      },
      BasketDiscount: {
        merge: true,
      },
      ProductCourse: {
        fields: {
          image: {
            merge: true,
          },
        },
      },
    },
  })

  client = new ApolloClient({
    link: createAuthLink(cookies).concat(errorLink).concat(httpLink),
    cache: cache.restore(initialState),
    ssrMode,
    connectToDevTools: IS_BROWSER,
  })

  return client
}

export const initApollo = ({
  initialState = {},
  cookies,
}: {
  initialState?: object
  cookies?: { [key: string]: string }
}) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!IS_BROWSER) {
    return createClient(initialState, cookies, true)
  }

  // Reuse client on the client-side
  if (!client) {
    client = createClient(initialState, cookies)
  }

  return client
}

export { API }
export default initApollo
