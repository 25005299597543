import { FacebookPixel, GoogleAnalytics } from '../../../services'
import { Config } from '../types'
import { getFacebookPixelContentCategory } from '../utils'

import { CHECKOUT_STARTED_BASKET } from './queries'

import initApollo from 'src/api'
import { API } from 'src/api'

export const checkoutStarted = async () => {
  const client = initApollo({})

  try {
    const response = await client.query<API.UseEcomCheckoutStartedBasket>({
      query: CHECKOUT_STARTED_BASKET,
    })

    const basket = response.data.basket

    if (basket) {
      // facebook pixel
      FacebookPixel.track('InitiateCheckout', {
        content_type: 'product',
        content_ids: basket.lineItems?.map((lineItem) => lineItem.product.id) || [],
        value: basket.total.value,
        contents: basket.lineItems?.map((lineItem) => ({
          id: lineItem.product.id,
          quantity: lineItem.quantity,
          item_price: lineItem.price.value,
        })),
        num_items: basket.lineItems?.length,
        currency: basket.total.currencyCode,
        content_category: getFacebookPixelContentCategory(
          basket.lineItems?.map((lineItem) => lineItem.product.__typename),
        ),
      })

      // google analytics
      GoogleAnalytics.track('begin_checkout', {
        currency: basket.total.currencyCode,
        items: basket.lineItems?.map((lineItem) => ({
          id: lineItem.product.id,
          name: lineItem.product.title,
          quantity: lineItem.quantity,
          price: lineItem.price.value,
          variant: lineItem.variant.id,
          category:
            lineItem.product.__typename === 'ProductCourse'
              ? lineItem.product.difficultyLevel
              : undefined,
        })),
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export type CheckoutStarted = Config<'checkout.started'>
