import React from 'react'

import { FacebookPixel, GoogleAnalytics, HelpScout } from '../../../../services'

import useTrackPage from './useTrackPage'
import useTrackIdentity from './useTrackIdentity'

const Utils = () => {
  useTrackPage()
  useTrackIdentity()

  return (
    <>
      <FacebookPixel.Script />
      <GoogleAnalytics.Script />
      <HelpScout.Script />
    </>
  )
}

export default Utils
