const ROUTES = {
  home: '/',

  about: '/about',

  'courses-index': '/courses',
  'courses-view': '/courses/:slug',

  cart: '/cart',
  book: '/learn-to-code-now-book',

  contact: '/contact',

  'membership-product': '/unlimited-membership',
  'membership-product-checkout': '/membership/checkout',
  'membership-product-checkout-complete': '/membership/checkout/complete',

  'discounts-view': '/discounts/:code',
  editor: '/editor',

  'hall-of-fame-index': '/hall-of-fame',
  'hall-of-fame-view': '/hall-of-fame/:slug',

  'help-center-index': '/help-center',
  'help-center-articles-view': '/help-center/articles/:id',
  'help-center-topics-view': '/help-center/topics/:slug',

  'checkout-steps': '/checkout/:step(info|billing|shipping|payment)?',
  'checkout-info': '/checkout/info',
  'checkout-billing': '/checkout/billing',
  'checkout-shipping': '/checkout/shipping',
  'checkout-payment': '/checkout/payment',
  'checkout-confirmation': '/checkout/confirmation',
  'checkout-exchange': '/checkout/exchange',
  'checkout-complete': '/checkout/complete',

  'jobs-index': '/jobs',
  'jobs-view': '/jobs/:slug',

  'scholarship-view': '/scholarships/:slug',

  teams: '/teams',

  'collections-index': '/collections',

  'partners-view': `/partners/:slug`,

  'terms-of-use': '/terms-of-use',
  'discrimination-policy': '/discrimination-policy',
  'privacy-policy': '/privacy-policy',
  'return-policy': '/return-policy',
  'code-of-conduct': '/code-of-conduct',
  press: '/press',

  'and-shopify': '/and/shopify',

  'not-found': undefined,
}

export default ROUTES
