import { useQuery } from '@apollo/client'
import React from 'react'

import * as API from '../types'

import { IS_LOGGED_IN } from './queries'

export const useSession = (): [
  boolean,
  boolean,
  { hasActiveMembership: boolean; userType?: API.UserType },
] => {
  const { data, loading } = useQuery<API.IsLoggedIn>(IS_LOGGED_IN, {
    fetchPolicy: 'cache-and-network',
    ssr: false,
  })

  return React.useMemo(
    () => [
      !!data?.session?.id,
      loading,
      {
        hasActiveMembership: data?.session?.type
          ? [API.UserType.MEMBER, API.UserType.TEAM_MEMBER].includes(data.session.type) ||
            data.session.isAdmin
          : false,
        userType: data?.session?.type,
      },
    ],
    [data, loading],
  )
}
