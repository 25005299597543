import { useRouter } from 'next/router'
import React from 'react'

type Props = {
  from?: string
  to: string
  statusCode?: number
}

const Redirect: React.FC<React.PropsWithChildren<Props>> = ({ from, to, statusCode }) => {
  const router = useRouter()

  React.useEffect(() => {
    router.replace(to)
  }, [])

  return null
}

export default Redirect
