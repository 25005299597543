import { HelpScout, GoogleAnalytics } from '../../../services'

import { SESSION } from './queries'

import initApollo, { API } from 'src/api'

export const loggedIn = async () => {
  const client = initApollo({})

  try {
    const response = await client.query<API.UseEvents_LoggedIn_Session>({
      query: SESSION,
    })

    const session = response.data.session

    if (session) {
      // helpscout
      HelpScout.identify({
        name: session.name || 'Unknown',
        email: session.email,
      })

      // google analytics
      GoogleAnalytics.identify({ id: session.id })
      GoogleAnalytics.setUserProperties({
        userType: session.type,
        teamId: session.team?.id,
        membershipStatus: session.membership?.status,
      })
    }
  } catch (e) {
    console.log(e)
  }
}
