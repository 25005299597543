import React from 'react'

import COOKIE_MANAGER from 'src/cookies'
import { API } from 'src/api'

const INITIAL_STATE = {
  [COOKIE_MANAGER['currency-code'].key]: API.CurrencyCode.USD,
  [COOKIE_MANAGER['discount-code'].key]: COOKIE_MANAGER['discount-code'].get(),
  [COOKIE_MANAGER['banner-dismissed-id'].key]: COOKIE_MANAGER['banner-dismissed-id'].get(),
  canonicalUrl: undefined,
}

export type State = typeof INITIAL_STATE

type Action =
  | {
      type: 'setCurrencyCode'
      payload: string | undefined
    }
  | {
      type: 'setDiscountCode'
      payload: string | undefined
    }
  | {
      type: 'setBannerDismissedId'
      payload: string | undefined
    }

export const AppStateContext = React.createContext<State>(INITIAL_STATE)
export const AppStateDispatchContext = React.createContext<React.Dispatch<Action>>(() => null)

const reducer = (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case 'setDiscountCode':
      return {
        ...state,
        [COOKIE_MANAGER['discount-code'].key]: action.payload,
      }
    case 'setBannerDismissedId':
      return {
        ...state,
        [COOKIE_MANAGER['banner-dismissed-id'].key]: action.payload,
      }

    default:
      return state
  }
}

interface StateProviderProps {
  initialState?: State
}

export const StateProvider: React.FC<React.PropsWithChildren<StateProviderProps>> = ({
  initialState = INITIAL_STATE,
  children,
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <AppStateDispatchContext.Provider value={dispatch}>
      <AppStateContext.Provider value={state}>{children}</AppStateContext.Provider>
    </AppStateDispatchContext.Provider>
  )
}

export const useAppState = () => React.useContext(AppStateContext)
export const useAppDispatch = () => React.useContext(AppStateDispatchContext)
