import { gql } from '@apollo/client'

import { BASKET_MANAGER_LINE_ITEM } from './fragments'

export const BASKET_CREATE = gql`
  mutation BasketCreate {
    basketCreate {
      id
    }
  }
`

export const BASKET_LINE_ITEMS_ADD = gql`
  mutation BasketLineItemsAdd($variantIds: [ID!]!) {
    basketLineItemsAdd(variantIds: $variantIds) {
      id
      lineItems {
        ...BasketManagerLineItem
      }
    }
  }

  ${BASKET_MANAGER_LINE_ITEM}
`
