import { ApolloClient } from '@apollo/client'

import { BASKET } from './useBasket/queries'

import COOKIE_MANAGER from 'src/cookies'

export const destroyBasket = (client: ApolloClient<object>) => {
  try {
    COOKIE_MANAGER['basket-id'].remove()

    client.writeQuery({
      query: BASKET,
      data: {
        basket: null,
      },
    })

    client.cache.gc()
  } catch (e) {}
}
