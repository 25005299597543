import uniq from 'lodash.uniq'

import { API } from 'src/api'
import { buildUrlClient } from 'src/routing'

export const buildProductUrl = (type: 'ProductBook' | 'ProductCourse', slug: string) =>
  buildUrlClient(type === 'ProductBook' ? `/${slug}` : `/courses/${slug}`)

export const getLowestPriceBook = (variants: API.EventsProductBook_variants_nodes[]) =>
  variants.map((variant) => variant.priceConversion).sort((a, b) => a.value - b.value)[0]

type Typenames = 'ProductBook' | 'ProductCourse'

const CONTENT_CATEGORY: Record<Typenames, string> = {
  ProductBook: 'BOOK',
  ProductCourse: 'PAID_COURSE',
}

export const getFacebookPixelContentCategory = (typenames: Typenames[] = []) => {
  const uniqueTypenames = uniq(typenames)

  switch (uniqueTypenames.length) {
    case 1: {
      return CONTENT_CATEGORY[uniqueTypenames[0]]
    }
    case 2: {
      return `${CONTENT_CATEGORY.ProductCourse}_AND_${CONTENT_CATEGORY.ProductBook}`
    }
    default: {
      return undefined
    }
  }
}
