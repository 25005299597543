import { gql } from '@apollo/client'

export const PRODUCT_COURSE_FRAGMENT = gql`
  fragment EventsProductCourse on ProductCourse {
    id
    slug
    difficultyLevel
    title
    lowestPrice {
      currencyCode
      value
    }
    image {
      url
    }
  }
`

export const PRODUCT_BOOK_FRAGMENT = gql`
  fragment EventsProductBook on ProductBook {
    id
    slug
    title
    image {
      url
    }
    lowestPrice {
      currencyCode
      value
    }
    variants {
      nodes {
        priceConversion {
          value
          currencyCode
        }
      }
    }
  }
`
