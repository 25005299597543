import React from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { BASKET_CREATE, BASKET_LINE_ITEMS_ADD } from './mutations'
import { BASKET } from './queries'

import events from 'src/events'
import { API } from 'src/api'
import COOKIE_MANAGER from 'src/cookies'

const useBasket = () => {
  const [createBasket, createBasketResponse] = useMutation<API.BasketCreate>(BASKET_CREATE)

  const { data: basketData } = useQuery<API.BasketBasket>(BASKET, {
    ssr: false,
  })

  const [lineItemsAdd, lineItemsAddResponse] = useMutation<
    API.BasketLineItemsAdd,
    API.BasketLineItemsAddVariables
  >(BASKET_LINE_ITEMS_ADD, {
    update: (cache, result) => {
      if (result.data?.basketLineItemsAdd) {
        try {
          cache.writeQuery({
            query: BASKET,
            data: {
              basket: result.data?.basketLineItemsAdd,
            },
          })
        } catch (e) {
          console.log('ERROR', e)
        }
      }
    },
  })

  const lineItemsAddFn = React.useCallback(
    async (variantIds: string[]) => {
      const basketId = COOKIE_MANAGER['basket-id'].get()

      if (!basketId) {
        try {
          const response = await createBasket()
          const newId = response.data?.basketCreate?.id

          if (newId) {
            COOKIE_MANAGER['basket-id'].set(newId)

            await lineItemsAdd({ variables: { variantIds } })

            variantIds.forEach((variantId) => events.productAdded({ variantId, quantity: 1 }))
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        try {
          await lineItemsAdd({ variables: { variantIds } })
          variantIds.forEach((variantId) =>
            events.productAdded({
              variantId,
              quantity: 1,
            }),
          )
        } catch (e) {
          console.log(e)
        }
      }
    },
    [createBasket, lineItemsAdd],
  )

  const addLineItems = React.useMemo<[typeof lineItemsAddFn, typeof lineItemsAddResponse]>(
    () => [
      lineItemsAddFn,
      {
        ...lineItemsAddResponse,
        loading: lineItemsAddResponse.loading || createBasketResponse.loading,
      },
    ],
    [lineItemsAddFn, lineItemsAddResponse, createBasketResponse],
  )

  const containsVariant = React.useCallback(
    (variantId: string) =>
      basketData?.basket?.lineItems?.some((item) => item.variant.id === variantId) || false,
    [basketData?.basket?.lineItems],
  )

  const containsProduct = React.useCallback(
    (productId: string) =>
      basketData?.basket?.lineItems?.some((item) => item.product.id === productId) || false,
    [basketData?.basket?.lineItems],
  )

  return React.useMemo(
    () => ({ addLineItems, containsVariant, containsProduct }),
    [
      // destroy,
      addLineItems,
      containsVariant,
      containsProduct,
    ],
  )
}

export default useBasket
