import { gql } from '@apollo/client'

export const INVOICE = gql`
  query UseEcom_SubscriptionOrderCompleted_Invoice($id: ID!) {
    session {
      id
      email
      type
      membership {
        id
        status
      }
    }

    membershipInvoice(id: $id) {
      id
      value {
        value
      }
      totalDiscount {
        value
      }
      discounts {
        ... on MembershipDiscountCoupon {
          stripeCouponId
        }
        ... on MembershipDiscountPromoCode {
          coupon {
            stripeCouponId
          }
        }
      }
      total {
        value
        currencyCode
      }
      product {
        id
        title
        price {
          id
          value
        }
      }
    }
  }
`
