import { gql } from '@apollo/client'

export const CURRENCIES = gql`
  query SiteFooterComponentCurrencies {
    currencies(perPage: 100) {
      nodes {
        code
        name
      }
    }
  }
`
