import { gql } from '@apollo/client'

export const BASKET = gql`
  query SiteHeaderComponentBasket {
    basket {
      lineItems {
        id
        quantity
      }
    }
  }
`

export const SETTINGS = gql`
  query SiteHeaderComponentSettings {
    settings {
      announcement {
        id
        body
      }
    }
  }
`

export const USER_FRAGMENT = gql`
  fragment SessionSiteHeaderComponent on Session {
    id
    name
    username
    email
    avatar {
      url
    }
    membership {
      id
    }
  }
`

export const SESSION = gql`
  query SiteHeaderComponentSession {
    session {
      ...SessionSiteHeaderComponent
    }
  }

  ${USER_FRAGMENT}
`
