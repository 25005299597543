import { useQuery } from '@apollo/client'
import React from 'react'

import QUERY from './queries'

import events from 'src/events'
import { API } from 'src/api'

const useTrackIdentity = () => {
  const ref = React.useRef(false)
  const { data, loading } = useQuery<API.UseTrackIdentity>(QUERY, {
    fetchPolicy: 'cache-and-network',
    ssr: false,
  })

  React.useEffect(() => {
    const wasLoggedIn = ref.current
    const isLoggedIn = !!data?.session

    if (loading) return

    if (isLoggedIn) {
      // logged in
      events.loggedIn()

      ref.current = true
    } else {
      // not logged in
      if (wasLoggedIn) {
        // user logged out
        events.loggedOut()
      } else {
        // user was never logged in
        events.anonymous()
      }
    }
  }, [data, loading])

  return null
}

export default useTrackIdentity
